import { put, call, fork, take, takeEvery } from 'redux-saga/effects';
import { SubmissionError } from 'redux-form';
import { REQUEST } from 'redux-config/reduxHelpers';
import { acceptError } from 'common/_redux';
import FileDownload from 'js-file-download';
import { getContactsSaga } from 'crm/contacts/_redux/sagas';

import * as api from '../api';
import * as ActionTypes from './actions';

export function* getGeonameContactByGIDSaga(gid, type) {
  if (!gid) {
    yield put(
      ActionTypes.getGeonameContactByGID.success({
        [type]: '',
      })
    );
  } else {
    try {
      const { status, data } = yield call(api.getGeonameContactByGID, gid);
      if (status < 300) {
        const res = {
          [type]: { label: data.name, value: data.gid },
        };
        yield put(ActionTypes.getGeonameContactByGID.success(res));
      } else {
        yield put(ActionTypes.getGeonameContactByGID.failure(data.detail));
      }
    } catch (e) {
      yield put(acceptError(e));
      yield put(ActionTypes.getGeonameContactByGID.failure());
      throw e;
    }
  }
}

export function* getGeonameContactByGIDWatcherSaga() {
  yield takeEvery(ActionTypes.GET_GEONAME_CONTACT_BY_GID[REQUEST], getGeonameContactByGIDSaga);
}

export function* getCrmUserDataSaga(id) {
  try {
    const { data, status } = yield call(api.getCrmUserData, id);

    if (status < 300) {
      yield put(ActionTypes.getCrmUserData.success(data));
      if (data.address) {
        if (data.address) {
          yield call(getGeonameContactByGIDSaga, data.address.country, 'country');
          yield call(getGeonameContactByGIDSaga, data.address.state, 'state');
        }
        // yield call(getGeonameContactByGIDSaga, data.nationality, 'nationality');
        // yield call(getGeonameContactByGIDSaga, data.taxResidence, 'taxResidence');
      }
    } else {
      yield put(acceptError(data.detail, true, '/crm/contacts'));
    }
  } catch (e) {
    yield put(acceptError(e.message, true, '/crm/contacts'));
    yield put(ActionTypes.getCrmUserData.failure(e));
  }
}
export function* getCrmUserDataWatcherSaga() {
  while (true) {
    const { userId } = yield take(ActionTypes.GET_CRM_USER_DATA[REQUEST]);
    yield call(getCrmUserDataSaga, userId);
  }
}

export function* getCrmUserAccountsSaga(id) {
  try {
    const { data, status } = yield call(api.getCrmUserAccounts, id);
    if (status < 300) {
      yield put(ActionTypes.getCrmUserAccounts.success(data));
    } else {
      yield put(acceptError(data.detail, true));
      yield put(ActionTypes.getCrmUserAccounts.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getCrmUserAccounts.failure(e));
  }
}
export function* getCrmUserAccountsWatcherSaga() {
  while (true) {
    const { id } = yield take(ActionTypes.GET_CRM_USER_ACCOUNTS[REQUEST]);
    yield call(getCrmUserAccountsSaga, id);
  }
}

export function* getCrmUserAccountDataSaga({ id, accountId }) {
  try {
    const { data, status } = yield call(api.getCrmUserAccountData, id, accountId);
    if (status < 300) {
      yield put(ActionTypes.getCrmUserAccountData.success(data));
    } else {
      yield put(acceptError(data.detail, true));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getCrmUserAccountData.failure(e));
  }
}
export function* getCrmUserAccountDataWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_CRM_USER_ACCOUNT_DATA[REQUEST]);
    yield call(getCrmUserAccountDataSaga, data);
  }
}

export function* getCrmAccountTradesSaga({ id, accountId, operation, search }) {
  try {
    const { data, status } = yield call(api.getCrmAccountTrades, id, accountId, operation, search);
    if (status < 300) {
      yield put(ActionTypes.getCrmAccountTrades.success(data));
    } else {
      yield put(acceptError(data.detail, true));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getCrmAccountTrades.failure(e));
  }
}
export function* getCrmAccountTradesWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_CRM_ACCOUNT_TRADES[REQUEST]);
    yield call(getCrmAccountTradesSaga, data);
  }
}

export function* getContactFavoritsSaga() {
  try {
    const { status, data } = yield call(api.getContactFavorites);
    if (status < 300) {
      yield put(ActionTypes.getContactFavorites.success(data));
    } else {
      yield put(acceptError(data.detail, true));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getContactFavorites.failure());
    throw e;
  }
}
export function* getContactFavoritsWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_CONTACT_FAVORITES[REQUEST]);
    yield call(getContactFavoritsSaga);
  }
}

export function* setContactFavoriteSaga({ id }) {
  try {
    const { status, data } = yield call(api.setContactFavorite, id);
    if (status < 300) {
      yield put(ActionTypes.setContactFavorite.success(data));
      yield call(getContactFavoritsSaga);
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.setContactFavorite.failure());
    throw e;
  }
}
export function* setContactFavoriteWatcherSaga() {
  while (true) {
    const payload = yield take(ActionTypes.SET_CONTACT_FAVORITE_CONTACT_PAGE[REQUEST]);
    yield call(setContactFavoriteSaga, payload);
  }
}

export function* deleteContactFavoriteSaga({ id }) {
  try {
    const { status, data } = yield call(api.deleteContactFavorite, id);
    if (status < 300) {
      yield put(ActionTypes.deleteContactFavorite.success(data));
      yield call(getContactFavoritsSaga);
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.deleteContactFavorite.failure());
    throw e;
  }
}
export function* deleteContactFavoriteWatcherSaga() {
  while (true) {
    const payload = yield take(ActionTypes.DELETE_CONTACT_FAVORITE_CONTACT_PAGE[REQUEST]);
    yield call(deleteContactFavoriteSaga, payload);
  }
}

export function* getCrmUserLogsSaga({ id, search }) {
  try {
    const { data, status } = yield call(api.getCrmUserLogs, id, search);

    if (status < 300) {
      yield put(ActionTypes.getCrmUserLogs.success(data));
    } else {
      yield put(acceptError(data.detail, true));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getCrmUserLogs.failure(e));
  }
}
export function* getCrmUserLogsWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_CRM_USER_LOGS[REQUEST]);
    yield call(getCrmUserLogsSaga, data);
  }
}

export function* getCrmUserFeedSaga({ id, search }) {
  try {
    const { data, status } = yield call(api.getCrmUserFeed, id, search);

    if (status < 300) {
      yield put(ActionTypes.getCrmUserFeed.success(data));
    } else {
      yield put(acceptError(data.detail, true, '/crm/contacts'));
    }
  } catch (e) {
    yield put(acceptError(e, true, '/crm/contacts'));
    yield put(ActionTypes.getCrmUserFeed.failure(e));
  }
}
export function* getCrmUserFeedWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_CRM_USER_FEED[REQUEST]);
    yield call(getCrmUserFeedSaga, data);
  }
}

export function* getCrmUserPaymentsSaga({ id, search }) {
  try {
    const { data, status } = yield call(api.getCrmUserPayments, id, search);

    if (status < 300) {
      yield put(ActionTypes.getCrmUserPayments.success(data));
    } else {
      yield put(acceptError(data.detail, true));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getCrmUserPayments.failure(e));
  }
}
export function* getCrmUserPaymentsWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_CRM_USER_PAYMENTS[REQUEST]);
    yield call(getCrmUserPaymentsSaga, data);
  }
}

export function* getCrmUserInternalTransfersSaga({ id, search }) {
  try {
    const { data, status } = yield call(api.getCrmUserInternalTransfers, id, search);

    if (status < 300) {
      yield put(ActionTypes.getCrmUserInternalTransfers.success(data));
    } else {
      yield put(acceptError(data.detail, true));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getCrmUserInternalTransfers.failure(e));
  }
}
export function* getCrmUserInternalTransfersWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_CRM_USER_INTERNAL_TRANSFERS[REQUEST]);
    yield call(getCrmUserInternalTransfersSaga, data);
  }
}

export function* getSalesStatuses() {
  try {
    const { data, status } = yield call(api.getSalesStatuses);

    if (status < 300) {
      yield put(ActionTypes.getSalesStatuses.success(data));
    } else {
      yield put(acceptError(data.detail, true));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getSalesStatuses.failure(e));
  }
}
export function* getSalesStatusesWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_SALES_STATUSES[REQUEST]);
    yield call(getSalesStatuses, data);
  }
}

export function* crmSetUserNameSaga(payload) {
  try {
    const { id, firstName, lastName } = payload;
    yield call(api.setUserName, id, firstName, lastName);
    yield put(ActionTypes.crmSetUserName.success());
    yield call(getCrmUserDataSaga, id);
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.crmSetUserName.failure());
  }
}
export function* crmSetUserNameWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.crmSetUserName.REQUEST);
    yield call(crmSetUserNameSaga, payload);
  }
}

export function* crmSetUserManagerSaga(payload) {
  try {
    const { userId, manager, reason, redirect, includeClients } = payload;
    const { status, data } = yield call(api.setUserManager, { userId, manager, reason, includeClients });
    if (status < 300) {
      yield put(ActionTypes.crmSetUserManager.success());
      yield call(getCrmUserDataSaga, userId);
      if (redirect) {
        return window.open('/crm/contacts');
      }
    } else {
      yield put(acceptError(data.detail, true));
      yield put(ActionTypes.crmSetUserManager.failure());
      yield call(getCrmUserDataSaga, userId);
      yield call(getCrmUserFeedSaga, { id: userId });
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.crmSetUserManager.failure());
  }
}
export function* crmSetUserManagerWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.crmSetUserManager.REQUEST);
    yield call(crmSetUserManagerSaga, payload);
  }
}

export function* crmSetUserIBManagerSaga(payload) {
  try {
    const { userId, ibManager } = payload;
    yield call(api.setUserIBManager, userId, ibManager);
    yield put(ActionTypes.crmSetUserIBManager.success());
    yield call(getCrmUserDataSaga, userId);
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.crmSetUserIBManager.failure());
  }
}
export function* crmSetUserIBManagerWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.crmSetUserIBManager.REQUEST);
    yield call(crmSetUserIBManagerSaga, payload);
  }
}

export function* crmSetUserPartnerCodeSaga(payload) {
  try {
    const { contactId, userId, partnerAccount } = payload;
    yield call(api.editUserPartnerCode, userId, partnerAccount);
    yield put(ActionTypes.crmSetUserPartnerCode.success());
    yield call(getCrmUserDataSaga, contactId);
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.crmSetUserPartnerCode.failure());
  }
}
export function* crmSetUserPartnerCodeWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.crmSetUserPartnerCode.REQUEST);
    yield call(crmSetUserPartnerCodeSaga, payload);
  }
}

export function* crmEditContactSaga(payload) {
  try {
    const { userId, reloadFeed, ...data } = payload;
    const { status } = yield call(api.editContact, userId, data);
    if (status < 300) {
      yield put(ActionTypes.crmEditContact.success());
    } else {
      yield put(acceptError(data.detail, true));
    }
    yield call(getCrmUserDataSaga, userId);
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.crmEditContact.failure());
  }
}
export function* crmEditContactWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.crmEditContact.REQUEST);
    yield call(crmEditContactSaga, payload);
  }
}

export function* getContactNotesSaga(id) {
  try {
    const { status, data } = yield call(api.getContactNotes, id);
    if (status < 300) {
      yield put(ActionTypes.getContactNotes.success(data));
    } else {
      yield put(ActionTypes.getContactNotes.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getContactNotes.failure());
    throw e;
  }
}

export function* getContactNotesWatcherSaga() {
  while (true) {
    const { id } = yield take(ActionTypes.GET_CONTACT_NOTES[REQUEST]);
    yield call(getContactNotesSaga, id);
  }
}

export function* createContactNoteSaga({ id, title, text, documentss }) {
  // FIXME: НЕ ПРИНИМАЕТ ПРОСТО documents
  try {
    yield call(api.createContactNote, id, {
      documents: documentss,
      title,
      text,
    });
    yield put(ActionTypes.createContactNote.success());
    yield call(getContactNotesSaga, id);
    yield call(getCrmUserFeedSaga, { id });
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.createContactNote.failure());
  }
}

export function* createContactNoteWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.createContactNote.REQUEST);
    yield call(createContactNoteSaga, payload);
  }
}

export function* editContactNoteSaga({ userId, noteId, title, text, documentss, reloadFeed }) {
  // FIXME: НЕ ПРИНИМАЕТ ПРОСТО documents
  try {
    yield call(api.editContactNote, userId, noteId, {
      title,
      text,
      documents: documentss,
    });
    yield put(ActionTypes.editContactNote.success());
    if (reloadFeed) {
      yield call(getCrmUserFeedSaga, { id: userId });
    } else {
      yield call(getContactNotesSaga, userId);
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.editContactNote.failure());
  }
}

export function* editContactNoteWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.editContactNote.REQUEST);
    yield call(editContactNoteSaga, payload);
  }
}

export function* deleteContactNoteSaga({ userId, noteId, reloadFeed }) {
  try {
    const { status, data } = yield call(api.deleteContactNote, userId, noteId);
    if (status < 300) {
      yield put(ActionTypes.deleteContactNote.success(data));
      if (reloadFeed) {
        yield call(getCrmUserFeedSaga, { id: userId });
      } else {
        yield call(getContactNotesSaga, userId);
      }
    } else {
      yield put(ActionTypes.deleteContactNote.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.deleteContactNote.failure());
  }
}

export function* deleteContactNoteWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.deleteContactNote[REQUEST]);
    yield call(deleteContactNoteSaga, payload);
  }
}

export function* createContactDocumentSaga({ id, body, reloadFeed }) {
  try {
    const { status, data } = yield call(api.createContactDocument, id, body);
    if (status < 300) {
      yield put(ActionTypes.createContactDocument.success(data));
      if (reloadFeed) {
        yield call(getCrmUserFeedSaga, { id });
      } else {
        yield call(getContactNotesSaga, id);
      }
    } else {
      const error = data.detail ? { _error: data.detail } : { _error: Object.values(data).flat() };
      yield put(ActionTypes.createContactDocument.failure(error));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.createContactDocument.failure(e));
  }
}

export function* createContactDocumentWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.createContactDocument.REQUEST);
    yield call(createContactDocumentSaga, payload);
  }
}

export function* removeContactNoteDocumentSaga({ userId, documentId }) {
  try {
    const { status, data } = yield call(api.removeContactNoteDocument, userId, documentId);
    if (status < 300) {
      yield put(ActionTypes.removeContactNoteDocument.success(data));
      yield call(getContactNotesSaga, userId);
    } else {
      yield put(ActionTypes.removeContactNoteDocument.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.removeContactNoteDocument.failure());
  }
}

export function* removeContactNoteDocumentWatcherSaga() {
  while (true) {
    const { data } = yield take(ActionTypes.REMOVE_CONTACT_NOTE_DOCUMENT[REQUEST]);
    yield call(removeContactNoteDocumentSaga, data);
  }
}

export function* removeFeedNoteDocumentSaga({ userId, documentId }) {
  try {
    const { status, data } = yield call(api.removeContactNoteDocument, userId, documentId);
    if (status < 300) {
      yield put(ActionTypes.removeFeedNoteDocument.success(data));
      yield call(getCrmUserFeedSaga, { id: userId });
    } else {
      yield put(ActionTypes.removeFeedNoteDocument.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.removeFeedNoteDocument.failure());
  }
}

export function* removeFeedNoteDocumentWatcherSaga() {
  while (true) {
    const { data } = yield take(ActionTypes.REMOVE_FEED_NOTE_DOCUMENT[REQUEST]);
    yield call(removeFeedNoteDocumentSaga, data);
  }
}

export function* getContactNoteDocumentSaga({ userId, documentId }) {
  try {
    const { data, headers } = yield call(api.getContactNoteDocument, userId, documentId);
    const fileName = headers['content-disposition'].replace('attachment; filename=', '').replaceAll('"', '');
    FileDownload(data, fileName);
    yield put(ActionTypes.getContactNoteDocument.success(data));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getContactNoteDocument.failure());
  }
}

export function* getContactNoteDocumentWatcherSaga() {
  while (true) {
    const { data } = yield take(ActionTypes.GET_CONTACT_NOTE_DOCUMENT[REQUEST]);
    yield call(getContactNoteDocumentSaga, data);
  }
}

export function* updateFeedCrmTaskSaga({ id, taskId, page, ...values }) {
  try {
    const { data, status } = yield call(api.updateCrmTask, taskId, values);
    if (status < 300) {
      yield put(ActionTypes.updateFeedCrmTask.success(data));
      yield call(getCrmUserFeedSaga, { id });
    } else {
      const error = new SubmissionError(data.subject ? { _error: data.subject } : data);
      yield put(ActionTypes.updateFeedCrmTask.failure(error));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.updateFeedCrmTask.failure());
    throw e;
  }
}

export function* updateFeedCrmTaskWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.updateFeedCrmTask.REQUEST);
    yield call(updateFeedCrmTaskSaga, payload);
  }
}

export function* addContactTagSaga(payload) {
  try {
    const { id, tag } = payload;
    const { data } = yield call(api.addContactTag, id, tag);
    yield put(ActionTypes.addContactTag.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.addContactTag.failure());
  }
}

export function* addContactTagWatcherSaga() {
  while (true) {
    const payload = yield take(ActionTypes.ADD_CONTACT_TAG.REQUEST);
    yield call(addContactTagSaga, payload);
  }
}

export function* deleteContactTagSaga(payload) {
  try {
    const { id, tag, search } = payload;
    const { data } = yield call(api.deleteContactTag, id, tag);
    yield put(ActionTypes.deleteContactTag.success(data));
    if (search) {
      yield call(getContactsSaga, { search });
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.deleteContactTag.failure());
  }
}

export function* deleteContactTagWatcherSaga() {
  while (true) {
    const payload = yield take(ActionTypes.DELETE_CONTACT_TAG.REQUEST);
    yield call(deleteContactTagSaga, payload);
  }
}
export default [
  fork(getCrmUserFeedWatcherSaga),
  fork(getGeonameContactByGIDWatcherSaga),
  fork(getCrmUserDataWatcherSaga),
  fork(getCrmUserAccountsWatcherSaga),
  fork(getCrmUserAccountDataWatcherSaga),
  fork(getCrmAccountTradesWatcherSaga),
  fork(getContactFavoritsWatcherSaga),
  fork(setContactFavoriteWatcherSaga),
  fork(deleteContactFavoriteWatcherSaga),
  fork(getCrmUserLogsWatcherSaga),
  fork(getCrmUserPaymentsWatcherSaga),
  fork(getCrmUserInternalTransfersWatcherSaga),
  fork(crmSetUserNameWatcherSaga),
  fork(crmSetUserManagerWatcherSaga),
  fork(crmSetUserIBManagerWatcherSaga),
  fork(crmSetUserPartnerCodeWatcherSaga),
  fork(getSalesStatusesWatcherSaga),
  fork(crmEditContactWatcherSaga),
  fork(getContactNotesWatcherSaga),
  fork(createContactNoteWatcherSaga),
  fork(deleteContactNoteWatcherSaga),
  fork(editContactNoteWatcherSaga),
  fork(createContactDocumentWatcherSaga),
  fork(removeContactNoteDocumentWatcherSaga),
  fork(removeFeedNoteDocumentWatcherSaga),
  fork(getContactNoteDocumentWatcherSaga),
  fork(updateFeedCrmTaskWatcherSaga),
  fork(addContactTagWatcherSaga),
  fork(deleteContactTagWatcherSaga),
];
