import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withTheme } from 'common/styling/theme';

import UserPayments from '../UserPayments';
import UserTotalPayments from '../UserTotalPayments';
import UserInternalTransfers from '../UserInternalTransfers';

import { staticStyles, getDynamicStyles } from './style';

const UserOperations = ({ user, accountsEnabled, tradersEnabled, addons, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const [activeTab, handleChangeTab] = useState('dw');

  return (
    <section className="UserOperations">
      {(accountsEnabled || tradersEnabled || addons.includes('walletAccount')) && (
        <div className="UserOperations__tabs">
          <FormattedMessage id="depositsAndWithdrawals">
            {txt => (
              <button
                type="button"
                className={classNames('UserOperations__tab', {
                  active: activeTab === 'dw',
                })}
                onClick={() => handleChangeTab('dw')}>
                {txt}
              </button>
            )}
          </FormattedMessage>
          <FormattedMessage id="boInternalTransfers">
            {txt => (
              <button
                type="button"
                className={classNames('UserOperations__tab', {
                  active: activeTab === 'transfers',
                })}
                onClick={() => handleChangeTab('transfers')}>
                {txt}
              </button>
            )}
          </FormattedMessage>
          <FormattedMessage id="totalPayments">
            {txt => (
              <button
                type="button"
                className={classNames('UserOperations__tab', {
                  active: activeTab === 'totalPayments',
                })}
                onClick={() => handleChangeTab('totalPayments')}>
                {txt}
              </button>
            )}
          </FormattedMessage>
        </div>
      )}
      <div className="UserOperations__content">
        {addons.includes('walletAccount') && (
          <div className="UserOperations__wallet-balance">
            <FormattedMessage id="justWalletBalance">
              {txt => <span className="UserOperations__wallet-balance-caption">{`${txt}:`}</span>}
            </FormattedMessage>
            {`$${user.walletBalance}`}
          </div>
        )}

        {activeTab === 'dw' && <UserPayments />}
        {activeTab === 'transfers' && (accountsEnabled || tradersEnabled || addons.includes('walletAccount')) && (
          <UserInternalTransfers />
        )}
        {activeTab === 'totalPayments' && <UserTotalPayments />}
      </div>

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </section>
  );
};

UserOperations.propTypes = {
  tradersEnabled: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  accountsEnabled: PropTypes.bool.isRequired,
  addons: PropTypes.array.isRequired,
  theme: PropTypes.object,
};

UserOperations.defaultProps = {
  theme: {},
};

export default connect(state => ({
  user: state.backoffice.users.user,
  tradersEnabled: state.interfaceConfig.internalTransfers.traders_enabled,
  accountsEnabled: state.interfaceConfig.internalTransfers.accounts_enabled,
  addons: state.interfaceConfig.addons,
}))(withTheme()(UserOperations));
