import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { withTheme } from 'common/styling/theme';
import { Collapsible } from 'common/components';
import MediaQuery from 'react-responsive';
import classNames from 'classnames';
import InfoPanel from '../InfoPanel';
import StrategyInfo from '../StrategyInfo/StrategyInfo';

import { REWARD_TYPES_TR_KEYS, COPY_TYPES_TR_KEYS, FREQUENCY_TR_KEYS } from '../../consts';

import { staticStyles, getDynamicStyles } from './style';

const week = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const drawComma = (items, itemIndex) => (items.length !== itemIndex + 1 ? ', ' : '');
const drawTradersFee = fee => (REWARD_TYPES_TR_KEYS[fee] ? <FormattedMessage id={REWARD_TYPES_TR_KEYS[fee]} /> : '一');
const drawCopyType = type => (COPY_TYPES_TR_KEYS[type] ? <FormattedMessage id={COPY_TYPES_TR_KEYS[type]} /> : '一');
const drawFrequency = frequency =>
  FREQUENCY_TR_KEYS[frequency] ? <FormattedMessage id={FREQUENCY_TR_KEYS[frequency]} /> : '一';

const getFixedNumber = (num, decimal) => Number(Number(num).toFixed(decimal));
const toPercentage = num => (!Number.isNaN(num) ? `${getFixedNumber(num * 100, 5)}%` : '一');
const toNiceDaysMonthly = days => <FormattedMessage id="justDaysOfMonth">{txt => `${txt}: ${days}`}</FormattedMessage>;
const toNiceDaysWeekly = days =>
  days.map((day, index) => (
    <FormattedMessage key={`strategyInfoPangel-toNiceDaysWeekly-${index}`} id={`just${week[day - 1]}`}>
      {txt => `${txt}${drawComma(days, index)}`}
    </FormattedMessage>
  ));
const StrategyInfoPanel = ({ strategyDetail, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const isAutoInvestment = strategyDetail.settings?.autoInvestment;

  const investBalanceOperationsId = useMemo(() => {
    if (isAutoInvestment) return 'investAuto';

    if (!isAutoInvestment) return 'justManual';
  }, [isAutoInvestment]);

  const strategyInfoCells = {
    justMonthlyYield: {
      captionId: 'justMonthlyYield',
      displayValue: toPercentage(strategyDetail.monthlyYield),
      value: strategyDetail.monthlyYield,
    },
    justYield: {
      captionId: 'justYield',
      displayValue: toPercentage(strategyDetail.totalYield),
      value: strategyDetail.totalYield,
    },
    justFeeRate: {
      captionId: 'justFeeRate',
      displayValue: toPercentage(strategyDetail.feeRate / 100),
    },
    ageAndWeeks: {
      captionId: 'ageAndWeeks',
      displayValue: strategyDetail.age,
    },
    justInvestorsCount: {
      captionId: 'justInvestorsCount',
      displayValue: strategyDetail.investorsNumber,
    },
    investBalanceOperations: {
      captionId: 'investBalanceOperations',
      displayValue: <FormattedMessage id={investBalanceOperationsId} />,
    },
    investTradersFee: {
      captionId: 'investTradersFee',
      displayValue: drawTradersFee(strategyDetail.fee),
    },
    strategyCopyType: {
      captionId: 'strategyCopyType',
      displayValue: drawCopyType(strategyDetail.copyType),
    },
  };

  const infoPanelCells = [
    {
      captionId: 'investTradersFeeFrequency',
      displayValue: drawFrequency(strategyDetail.frequency),
    },
    {
      captionId: 'investTradersFeeDays',
      displayValue:
        strategyDetail.frequency === 'Monthly'
          ? toNiceDaysMonthly(strategyDetail.settings.days || [])
          : toNiceDaysWeekly(strategyDetail.settings.days || []),
    },
    {
      captionId: 'investTradersFeeTime',
      displayValue: `${strategyDetail.time >= 10 ? strategyDetail.time : `0${strategyDetail.time || 0}`}:00`,
    },
  ];

  const handleOpenCollapse = () => {};

  return (
    <div className="StrategyInfoPanel">
      <StrategyInfo cells={strategyInfoCells} />

      <MediaQuery query="(min-width: 768px)">
        <div className={classNames('StrategyInfoPanel__info', { description: strategyDetail.description })}>
          <div className="StrategyInfoPanel__info-content">
            <FormattedMessage id="investTradersFeeSettings">{txt => <h3>{txt}</h3>}</FormattedMessage>
            <InfoPanel cells={infoPanelCells} />
          </div>
          {strategyDetail.description && (
            <div className="StrategyInfoPanel__info-content">
              <FormattedMessage id="justDescription">{txt => <h3>{txt}</h3>}</FormattedMessage>
              <div
                className="StrategyInfoPanel__description"
                dangerouslySetInnerHTML={{ __html: strategyDetail.description }}
              />
            </div>
          )}
        </div>
      </MediaQuery>

      <MediaQuery query="(max-width: 767px)">
        <Collapsible captionId="investTradersFeeSettings" handleOpen={handleOpenCollapse} opened>
          <InfoPanel cells={infoPanelCells} />
        </Collapsible>
        {strategyDetail.description && (
          <Collapsible captionId="justDescription" handleOpen={handleOpenCollapse} opened>
            <div
              className="StrategyInfoPanel__description"
              dangerouslySetInnerHTML={{ __html: strategyDetail.description }}
            />
          </Collapsible>
        )}
      </MediaQuery>

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

StrategyInfoPanel.propTypes = {
  strategyDetail: PropTypes.object,
  theme: PropTypes.object,
};

StrategyInfoPanel.defaultProps = {
  strategyDetail: {},
  theme: {},
};

export default withTheme()(StrategyInfoPanel);
