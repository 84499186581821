import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MoneyCell, NoItemsPanel, DateCell, ActionsButton } from 'common/components';
import EditTaskForm from 'crm/tasks/_components/EditTaskForm';
import CloseTaskForm from 'crm/tasks/_components/CloseTaskForm';
import { EditIcon, StopIcon } from 'common/icons';
import { compose } from 'redux';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import BasicCRMModal from 'crm/_components/BasicCRMModal';
import NoQueryTable from 'crm/_newComponents/noQuery/NoQueryTable';

import noPaymentsImage from 'common/images/noPayments.svg';

import { staticStyles } from './style';

const MyTasksTable = ({ data, onRowClick, getData, dataIsLoaded }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCLoseOpen, setIsCloseOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const columns = [
    {
      accessor: 'fullName',
      Header: 'crmFullName',
      isSortable: true,
      blueBg: true,
      // TODO: fullName
      Cell: ({ rowData: { fullName } }) => fullName,
    },
    {
      accessor: 'plannedDepositAmount',
      Header: 'crmPlannedDepositAmount',
      isSortable: true,
      blueBg: true,
      // TODO: plannedDepositAmount
      Cell: ({ rowData: { plannedDepositAmount } }) => plannedDepositAmount ?? '一',
    },
    {
      accessor: 'balance',
      Header: 'crmBalance',
      isSortable: false,
      blueBg: true,
      // TODO: balance
      Cell: ({ rowData: { balance } }) => <MoneyCell value={balance} crmStyle />,
    },
    {
      accessor: 'assignedTo',
      Header: 'crmAssignedTo',
      isSortable: false,
      blueBg: true,
      // TODO: manager
      Cell: ({ rowData: { assignedTo } }) => assignedTo.label,
    },
    {
      accessor: 'expirationDate',
      Header: 'crmExpirationDate',
      isSortable: true,
      Cell: ({ rowData: { expirationDate } }) => (expirationDate ? <DateCell value={expirationDate} inLine /> : '---'),
    },
    {
      accessor: 'country',
      Header: 'crmCountry',
      isSortable: true,
      // TODO: country
      Cell: ({ rowData: { country } }) => country ?? '一',
    },
    {
      accessor: 'type',
      Header: 'crmType',
      isSortable: false,
      // TODO: monitoring
      Cell: ({ rowData: { type } }) => type,
    },
    {
      accessor: 'isOverdue',
      Header: 'crmIsOverdue',
      isSortable: false,
      // TODO:isOverdue
      Cell: ({ rowData: { isOverdue } }) => (isOverdue ? 'overdue' : '一'),
    },
    {
      accessor: 'status',
      Header: 'justStatus',
      isSortable: true,
      // TODO:isOverdue
      Cell: ({ rowData: { status } }) => (
        <span
          className={classNames('StatusCell', {
            'StatusCell--Open': status === 'OPEN',
            'StatusCell--Closed': status === 'CLOSED',
          })}>
          {status}
        </span>
      ),
    },

    {
      accessor: 'description',
      Header: 'crmComment',
      overflowText: true,
      isSortable: false,
      Cell: ({ rowData: { description } }) => description ?? '一',
    },
    {
      accessor: 'managerReaction',
      Header: 'crmManagerReaction',
      overflowText: true,
      isSortable: false,
      Cell: ({ rowData: { managerReaction } }) => managerReaction ?? '一',
    },
    {
      accessor: 'comment',
      Header: 'crmManagerComment',
      overflowText: true,
      isSortable: false,
      Cell: ({ rowData: { comment } }) => comment ?? '一',
    },
    {
      accessor: 'actions',
      Header: 'justActions',
      isSortable: false,
      // TODO: text
      Cell: ({ rowData }) => (
        <div className="actions">
          <ActionsButton
            deleteType
            disabled={rowData.status === 'CLOSED'}
            onClickFunc={e => {
              e.stopPropagation();
              setSelectedTask(rowData);
              setIsCloseOpen(true);
            }}>
            <StopIcon />
          </ActionsButton>

          <ActionsButton
            edit
            disabled={rowData.status === 'CLOSED'}
            onClickFunc={e => {
              e.stopPropagation();
              setSelectedTask(rowData);
              setIsOpen(true);
            }}>
            <EditIcon />
          </ActionsButton>
        </div>
      ),
    },
  ];
  /* eslint-enable */

  return (
    <div className="MyTasksTable">
      {data?.results?.length === 0 ? (
        <NoItemsPanel icon={noPaymentsImage} containerMaxHeight captionSlug="crmThereAreNoItemsMatchingParameters" />
      ) : (
        <NoQueryTable
          data={data}
          dataIsLoaded={dataIsLoaded}
          columns={columns}
          onRowClick={onRowClick}
          getData={getData}
        />
      )}

      <BasicCRMModal
        captionId="crmEditTask"
        isOpen={isOpen}
        onRequestClose={() => {
          setIsOpen(false);
          setSelectedTask(null);
        }}>
        <EditTaskForm
          onRequestClose={() => {
            setIsOpen(false);
            setSelectedTask(null);
          }}
          initialValues={{
            ...selectedTask,
            assignedTo: { value: selectedTask?.assignedTo?.id, label: selectedTask?.assignedTo?.fullName },
          }}
          {...selectedTask}
        />
      </BasicCRMModal>
      <BasicCRMModal
        captionId="crmCloseTask"
        isOpen={isCLoseOpen}
        onRequestClose={() => {
          setIsCloseOpen(false);
          setSelectedTask(null);
        }}>
        <CloseTaskForm
          onRequestClose={() => {
            setIsCloseOpen(false);
            setSelectedTask(null);
          }}
          {...selectedTask}
        />
      </BasicCRMModal>

      <style jsx>{staticStyles}</style>
    </div>
  );
};

MyTasksTable.propTypes = {
  data: PropTypes.object.isRequired,
  getData: PropTypes.func.isRequired,
  onRowClick: PropTypes.func,
};

MyTasksTable.defaultProps = {
  onRowClick: null,
};

export default compose(withRouter)(MyTasksTable);
