import { SubmissionError } from 'redux-form';
import { put, call, fork, take } from 'redux-saga/effects';

import { REQUEST } from 'redux-config/reduxHelpers';
import { acceptError } from 'common/_redux';
import { getCrmUserFeedSaga } from 'crm/users/_redux/sagas';

import * as api from '../api';
import * as ActionTypes from './actions';

export function* getOverdueTasksSaga(search) {
  try {
    const query = search || { page: 1, page_size: 5 };
    const { status, data } = yield call(api.getCrmPlanOverdue, query);
    if (status < 300) {
      yield put(ActionTypes.getOverdueTasks.success(data));
    } else {
      yield put(acceptError(data.detail, true));
      yield put(ActionTypes.getOverdueTasks.failure());
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getOverdueTasks.failure());
  }
}
export function* getOverdueTasksWatcherSaga() {
  while (true) {
    const { search } = yield take(ActionTypes.GET_OVERDUE_TASKS[REQUEST]);
    yield call(getOverdueTasksSaga, search);
  }
}

export function* getTodayTasksSaga(search) {
  try {
    const query = search || { page: 1, page_size: 5 };
    const { status, data } = yield call(api.getCrmPlanToday, query);
    if (status < 300) {
      yield put(ActionTypes.getTodayTasks.success(data));
    } else {
      yield put(acceptError(data.detail, true));
      yield put(ActionTypes.getTodayTasks.failure());
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getTodayTasks.failure());
  }
}
export function* getTodayTasksWatcherSaga() {
  while (true) {
    const { search } = yield take(ActionTypes.GET_TODAY_TASKS[REQUEST]);
    yield call(getTodayTasksSaga, search);
  }
}

export function* getTomorrowTasksSaga(search) {
  try {
    const query = search || { page: 1, page_size: 5 };
    const { status, data } = yield call(api.getCrmPlanTomorrow, query);
    if (status < 300) {
      yield put(ActionTypes.getTomorrowTasks.success(data));
    } else {
      yield put(acceptError(data.detail, true));
      yield put(ActionTypes.getTomorrowTasks.failure());
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getTomorrowTasks.failure());
  }
}
export function* getTomorrowTasksWatcherSaga() {
  while (true) {
    const { search } = yield take(ActionTypes.GET_TOMORROW_TASKS[REQUEST]);
    yield call(getTomorrowTasksSaga, search);
  }
}

export function* getAllCrmTasksSaga({ search }) {
  try {
    const { status: openStatus, data: allOpenTasks } = yield call(api.getAllOpenCrmTasks, search);
    const { status: closedStatus, data: allClosedTasks } = yield call(api.getAllClosedCrmTasks, search);
    if (openStatus < 300 && closedStatus < 300) {
      yield put(ActionTypes.getAllCrmTasks.success({ allOpenTasks, allClosedTasks }));
    } else {
      yield put(ActionTypes.getAllCrmTasks.failure(allOpenTasks?.detail || allClosedTasks?.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getAllCrmTasks.failure());
    throw e;
  }
}

export function* getAllCrmTasksWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_ALL_CRM_TASKS[REQUEST]);
    yield call(getAllCrmTasksSaga, data);
  }
}

export function* getAllReassignsTasksSaga({ search }) {
  try {
    const { status: openStatus, data: allOpenReassignsTasks } = yield call(api.getAllOpenReassignsTasks, search);
    const { status: closedStatus, data: allClosedReassignsTasks } = yield call(api.getAllClosedReassignsTasks, search);
    if (openStatus < 300 && closedStatus < 300) {
      yield put(ActionTypes.getAllReassignsTasks.success({ allOpenReassignsTasks, allClosedReassignsTasks }));
    } else {
      yield put(
        ActionTypes.getAllReassignsTasks.failure(allOpenReassignsTasks?.detail || allClosedReassignsTasks?.detail)
      );
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getAllReassignsTasks.failure());
    throw e;
  }
}

export function* getAllReassignsTasksWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_ALL_REASSIGNS_TASKS[REQUEST]);
    yield call(getAllReassignsTasksSaga, data);
  }
}

export function* getAllMyCrmTasksSaga({ search, managerId }) {
  try {
    const { status: openStatus, data: allMyOpenTasks } = yield call(api.getAllMyOpenCrmTasks, search, managerId);
    const { status: closedStatus, data: allMyClosedTasks } = yield call(api.getAllMyClosedCrmTasks, search, managerId);
    if (openStatus < 300 && closedStatus < 300) {
      yield put(ActionTypes.getAllMyCrmTasks.success({ allMyOpenTasks, allMyClosedTasks }));
    } else {
      yield put(ActionTypes.getAllMyCrmTasks.failure(allMyOpenTasks?.detail || allMyClosedTasks?.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getAllMyCrmTasks.failure());
    throw e;
  }
}

export function* getAllMyCrmTasksWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_ALL_MY_CRM_TASKS[REQUEST]);
    console.log('2');
    yield call(getAllMyCrmTasksSaga, data);
  }
}

export function* getTaskDetailSaga({ id }) {
  try {
    const { status, data } = yield call(api.getCrmTask, id);
    if (status < 300) {
      yield put(ActionTypes.getCrmTask.success(data));
    } else {
      yield put(ActionTypes.getCrmTask.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getCrmTask.failure());
    throw e;
  }
}

export function* getTaskDetailWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_CRM_TASK[REQUEST]);
    yield call(getTaskDetailSaga, data);
  }
}

export function* createTaskSaga({ currentPage, ...payload }) {
  try {
    const { data, status } = yield call(api.createCrmTask, payload);
    if (status < 300) {
      yield put(ActionTypes.createCrmTask.success(data));
      yield call(getOverdueTasksSaga);
      yield call(getTodayTasksSaga);
      yield call(getTomorrowTasksSaga);
      if (currentPage === 'contactPage') {
        yield call(getCrmUserFeedSaga, { id: payload.subject });
      } else {
        yield call(getAllCrmTasksSaga, { search: { page_size: 10 } });
      }
    } else {
      const error = new SubmissionError(
        data[Object.keys(data)[0]].subject ? { _error: data[Object.keys(data)[0]].subject } : 'There was an error'
      );
      yield put(ActionTypes.createCrmTask.failure(error));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.createCrmTask.failure());
    throw e;
  }
}

export function* createTaskWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.createCrmTask.REQUEST);
    yield call(createTaskSaga, payload);
  }
}

export function* updateTaskSaga({ id, page, contactId, managerId, ...values }) {
  try {
    const { data, status } = yield call(api.updateCrmTask, id, values);
    if (status < 300) {
      yield put(ActionTypes.updateCrmTask.success(data));
      if (page === 'detail') {
        yield call(getTaskDetailSaga, { id });
      } else if (contactId) {
        yield call(getCrmUserFeedSaga, { id: contactId, search: { page_size: 10 } });
      } else if (managerId) {
        yield call(getAllCrmTasksSaga, { search: { page_size: 10 }, managerId });
        yield call(getAllMyCrmTasksSaga, { search: { page_size: 10 }, managerId });
      }
      yield call(getOverdueTasksSaga);
      yield call(getTodayTasksSaga);
      yield call(getTomorrowTasksSaga);
    } else {
      const error = new SubmissionError(data.subject ? { _error: data.subject } : data);
      yield put(ActionTypes.updateCrmTask.failure(error));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.updateCrmTask.failure());
    throw e;
  }
}

export function* updateTaskWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.updateCrmTask.REQUEST);
    yield call(updateTaskSaga, payload);
  }
}

export function* newChangeReassignTaskStatusSaga({ taskId, status: newTaskStatus, reason }) {
  try {
    const { data, status } = yield call(api.newChangeReassignTaskStatus, taskId, newTaskStatus, reason);
    if (status < 300) {
      yield put(ActionTypes.newChangeReassignTaskStatus.success(data));

      yield call(getAllReassignsTasksSaga, { search: { page_size: 10 } });
    } else {
      const error = new SubmissionError(data.detail ? data.detail : { ...data });
      yield put(ActionTypes.newChangeReassignTaskStatus.failure(error));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.newChangeReassignTaskStatus.failure());
    throw e;
  }
}

export function* newChangeReassignTaskStatusWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.newChangeReassignTaskStatus.REQUEST);
    yield call(newChangeReassignTaskStatusSaga, payload);
  }
}

export function* getManagersSaga() {
  try {
    const { status, data } = yield call(api.getCrmManagers);
    if (status < 300) {
      yield put(ActionTypes.getCrmManagers.success(data));
    } else {
      yield put(ActionTypes.getCrmManagers.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getCrmManagers.failure());
    throw e;
  }
}

export function* getManagersWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_CRM_MANAGERS[REQUEST]);
    yield call(getManagersSaga);
  }
}

export default [
  fork(getOverdueTasksWatcherSaga),
  fork(getTodayTasksWatcherSaga),
  fork(getTomorrowTasksWatcherSaga),
  fork(getAllCrmTasksWatcherSaga),
  fork(getAllReassignsTasksWatcherSaga),
  fork(getTaskDetailWatcherSaga),
  fork(createTaskWatcherSaga),
  fork(updateTaskWatcherSaga),
  fork(getManagersWatcherSaga),
  fork(getAllMyCrmTasksWatcherSaga),
  fork(newChangeReassignTaskStatusWatcherSaga),
];
