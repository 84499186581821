import { put, call, fork, take } from 'redux-saga/effects';
import FileDownload from 'js-file-download';
import { acceptError } from 'common/_redux';
import { REQUEST } from 'redux-config/reduxHelpers';
import { SubmissionError } from 'redux-form';
import * as api from '../api';
import * as ActionTypes from './actions';

export function* getCommissionsListSaga(payload) {
  try {
    const { status, data } = yield call(api.getCommissionsList, payload);
    if (status < 300) {
      yield put(ActionTypes.getCommissionsList.success(data));
    } else {
      yield put(ActionTypes.getCommissionsList.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getCommissionsList.failure(e));
  }
}
export function* getCommissionsListWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.getCommissionsList.REQUEST);
    yield call(getCommissionsListSaga, payload);
  }
}

export function* editCommissionSaga({ commissionId, amount }) {
  try {
    const { status, data } = yield call(api.editCommission, commissionId, amount);
    if (status < 300) {
      yield put(ActionTypes.editCommission.success(data));
    } else {
      const formError = new SubmissionError({ _error: data || 'Edit amount error' });
      yield put(ActionTypes.editCommission.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.editCommission.failure(e));
  }
}
export function* editCommissionWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.editCommission.REQUEST);
    yield call(editCommissionSaga, payload);
  }
}

export function* getRewardFrequenciesSaga() {
  try {
    const { status, data } = yield call(api.getRewardFrequencies);
    if (status < 300) {
      yield put(ActionTypes.getRewardFrequencies.success(data));
    } else {
      yield put(ActionTypes.getRewardFrequencies.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getRewardFrequencies.failure());
    throw e;
  }
}
export function* getRewardFrequenciesWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_REWARD_FREQUENCIES[REQUEST]);
    yield call(getRewardFrequenciesSaga);
  }
}

export function* payCommissionsSaga({ commissionIds, filter }) {
  try {
    const { status, data } = yield call(api.payCommissions, { commissionIds });
    if (status < 300) {
      yield put(ActionTypes.payCommissions.success(data));
      yield call(getCommissionsListSaga, filter);
    } else {
      const formError = new SubmissionError(data.detail ? { _error: data.detail } : data);
      yield put(ActionTypes.payCommissions.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.payCommissions.failure(e));
  }
}
export function* payCommissionsWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.payCommissions.REQUEST);
    yield call(payCommissionsSaga, payload);
  }
}

export function* rejectRewardsSaga({ commissionIds, filter }) {
  try {
    const { status, data } = yield call(api.rejectRewards, { commissionIds });
    if (status < 300) {
      yield put(ActionTypes.rejectRewards.success(data));
      yield call(getCommissionsListSaga, filter);
    } else {
      const formError = new SubmissionError(data?.detail ? { _error: data.detail } : data);
      yield put(ActionTypes.rejectRewards.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.rejectRewards.failure(e));
  }
}
export function* rejectRewardsWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.rejectRewards.REQUEST);
    yield call(rejectRewardsSaga, payload);
  }
}

export function* boGetPartnershipRequestsSaga({ search }) {
  try {
    const { status, data } = yield call(api.boGetPartnershipRequests, search);
    if (status < 300) {
      yield put(ActionTypes.boGetPartnershipRequests.success(data));
    } else {
      yield put(ActionTypes.boGetPartnershipRequests.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.boGetPartnershipRequests.failure());
    throw e;
  }
}

export function* boGetPartnershipRequestsWatcherSaga() {
  while (true) {
    const { search } = yield take(ActionTypes.BO_GET_PARTNERSHIP_REQUESTS[REQUEST]);
    yield call(boGetPartnershipRequestsSaga, search);
  }
}

export function* boGetPartnershipRequestSaga(requestId) {
  try {
    const { status, data } = yield call(api.boGetPartnershipRequest, requestId);
    if (status < 300) {
      yield put(ActionTypes.boGetPartnershipRequests.success(data));
    } else {
      yield put(ActionTypes.boGetPartnershipRequests.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.boGetPartnershipRequests.failure());
    throw e;
  }
}

export function* boGetPartnershipRequestWatcherSaga() {
  while (true) {
    const { requestId } = yield take(ActionTypes.BO_GET_PARTNERSHIP_REQUEST[REQUEST]);
    yield call(boGetPartnershipRequestSaga, requestId);
  }
}

export function* createPartnerAccountsExportsSaga(ext) {
  try {
    const { data } = yield call(api.createPartnerAccountsExport, ext);
    yield put(ActionTypes.createPartnerAccountsExport.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.createPartnerAccountsExport.failure(e));
  }
}
export function* createPartnerAccountsExportsWatcherSaga() {
  while (true) {
    const { ext } = yield take(ActionTypes.CREATE_PARTNER_ACCOUNTS_EXPORT[REQUEST]);
    yield call(createPartnerAccountsExportsSaga, ext);
  }
}

export function* getPartnerAccountsExportsSaga(search) {
  try {
    const { data } = yield call(api.getPartnerAccountsExports, search);
    yield put(ActionTypes.getPartnerAccountsExports.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getPartnerAccountsExports.failure(e));
  }
}
export function* getPartnerAccountsExportsWatcherSaga() {
  while (true) {
    const { search } = yield take(ActionTypes.GET_PARTNER_ACCOUNTS_EXPORTS[REQUEST]);
    yield call(getPartnerAccountsExportsSaga, search);
  }
}

export function* downloadPartnerAccountsExportSaga({ id, name }) {
  try {
    const { data } = yield call(api.downloadPartnerAccountsExport, id, name);
    yield put(ActionTypes.downloadPartnerAccountsExport.success(data));
    FileDownload(data, name);
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.downloadPartnerAccountsExport.failure(e));
  }
}
export function* downloadPartnerAccountsExportWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.DOWNLOAD_PARTNER_ACCOUNTS_EXPORT[REQUEST]);
    yield call(downloadPartnerAccountsExportSaga, data);
  }
}

export function* createPartnerCommissionsExportsSaga(ext) {
  try {
    const { data } = yield call(api.createPartnerCommissionExport, ext);
    yield put(ActionTypes.createPartnerCommissionsExport.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.createPartnerCommissionsExport.failure(e));
  }
}
export function* createPartnerCommissionsExportsWatcherSaga() {
  while (true) {
    const { ext } = yield take(ActionTypes.CREATE_PARTNER_COMMISSIONS_EXPORT[REQUEST]);
    yield call(createPartnerCommissionsExportsSaga, ext);
  }
}

export function* getPartnerCommissionsExportsSaga(search) {
  try {
    const { data } = yield call(api.getPartnerCommissionExports, search);
    yield put(ActionTypes.getPartnerCommissionsExports.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getPartnerCommissionsExports.failure(e));
  }
}
export function* getPartnerCommissionsExportsWatcherSaga() {
  while (true) {
    const { search } = yield take(ActionTypes.GET_PARTNER_COMMISSIONS_EXPORTS[REQUEST]);
    yield call(getPartnerCommissionsExportsSaga, search);
  }
}

export function* downloadPartnerCommissionsExportSaga({ id, name }) {
  try {
    const { data } = yield call(api.downloadPartnerCommissionExport, id, name);
    yield put(ActionTypes.downloadPartnerCommissionsExport.success(data));
    FileDownload(data, name);
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.downloadPartnerCommissionsExport.failure(e));
  }
}
export function* downloadPartnerCommissionsExportWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.DOWNLOAD_PARTNER_COMMISSIONS_EXPORT[REQUEST]);
    yield call(downloadPartnerCommissionsExportSaga, data);
  }
}

export default [
  fork(getCommissionsListWatcherSaga),
  fork(editCommissionWatcherSaga),
  fork(payCommissionsWatcherSaga),
  fork(rejectRewardsWatcherSaga),
  fork(getRewardFrequenciesWatcherSaga),
  fork(boGetPartnershipRequestsWatcherSaga),
  fork(boGetPartnershipRequestWatcherSaga),
  fork(createPartnerAccountsExportsWatcherSaga),
  fork(getPartnerAccountsExportsWatcherSaga),
  fork(downloadPartnerAccountsExportWatcherSaga),
  fork(createPartnerCommissionsExportsWatcherSaga),
  fork(getPartnerCommissionsExportsWatcherSaga),
  fork(downloadPartnerCommissionsExportWatcherSaga),
];
