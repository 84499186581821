import { createFormAction } from 'redux-form-saga';
import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';

// error handler
export const ERROR_ACCEPT = 'ERROR_ACCEPT';
export const ERROR_REJECT = 'ERROR_REJECT';

export const acceptError = (e, critical = false, redirect) => action(ERROR_ACCEPT, { error: e, critical, redirect });
export const rejectError = () => action(ERROR_REJECT);

// partner reports
export const GET_REPORT_TEMPLATES = createRequestTypes('GET_REPORT_TEMPLATES');
export const getReportTemplates = {
  request: data => action(GET_REPORT_TEMPLATES[REQUEST], data),
  success: data => action(GET_REPORT_TEMPLATES[SUCCESS], { data }),
  failure: error => action(GET_REPORT_TEMPLATES[FAILURE], { error }),
};

export const executeReport = createFormAction('EXECUTE_REPORT');

export const EXECUTE_REPORT_DATA = createRequestTypes('EXECUTE_REPORT_DATA');
export const executeReportData = {
  request: () => action(EXECUTE_REPORT_DATA[REQUEST]),
  success: data => action(EXECUTE_REPORT_DATA[SUCCESS], { data }),
  failure: error => action(EXECUTE_REPORT_DATA[FAILURE], { error }),
};

export const exportReports = createFormAction('EXPORT_REPORTS');

export const createPeriodicalReport = createFormAction('CREATE_PERIODICAL_REPORT');

export const GET_PERIODICAL_REPORT_LIST = createRequestTypes('GET_PERIODICAL_REPORT_LIST');
export const getPeriodicalReportList = {
  request: data => action(GET_PERIODICAL_REPORT_LIST[REQUEST], data),
  success: data => action(GET_PERIODICAL_REPORT_LIST[SUCCESS], { data }),
  failure: error => action(GET_PERIODICAL_REPORT_LIST[FAILURE], { error }),
};

export const DELETE_REPORT = createRequestTypes('DELETE_REPORT');
export const deleteReport = {
  request: data => action(DELETE_REPORT[REQUEST], data),
  success: () => action(DELETE_REPORT[SUCCESS]),
  failure: error => action(DELETE_REPORT[FAILURE], { error }),
};

export const GET_SAVED_REPORTS = createRequestTypes('GET_SAVED_REPORTS');
export const getSavedReports = {
  request: data => action(GET_SAVED_REPORTS[REQUEST], data),
  success: data => action(GET_SAVED_REPORTS[SUCCESS], { data }),
  failure: error => action(GET_SAVED_REPORTS[FAILURE], { error }),
};

export const retrieveSavedReport = createFormAction('RETRIEVE_SAVED_REPORT');

export const RETRIEVE_AND_SHOW_SAVED_REPORT = createRequestTypes('RETRIEVE_AND_SHOW_SAVED_REPORT');
export const retrieveAndShowSavedReport = {
  request: data => action(RETRIEVE_AND_SHOW_SAVED_REPORT[REQUEST], data),
  success: data => action(RETRIEVE_AND_SHOW_SAVED_REPORT[SUCCESS], { data }),
  failure: error => action(RETRIEVE_AND_SHOW_SAVED_REPORT[FAILURE], { error }),
};

export const GET_BO_USER_EXPORTS = createRequestTypes('GET_BO_USER_EXPORTS');
export const getBackofficeUserExports = {
  request: data => action(GET_BO_USER_EXPORTS[REQUEST], data),
  success: data => action(GET_BO_USER_EXPORTS[SUCCESS], { data }),
  failure: error => action(GET_BO_USER_EXPORTS[FAILURE], { error }),
};

export const CREATE_BO_USER_EXPORTS = createRequestTypes('CREATE_BO_USER_EXPORTS');
export const createBackofficeUserExports = {
  request: () => action(CREATE_BO_USER_EXPORTS[REQUEST]),
  success: data => action(CREATE_BO_USER_EXPORTS[SUCCESS], { data }),
  failure: error => action(CREATE_BO_USER_EXPORTS[FAILURE], { error }),
};

export const SET_CREATE_ERROR = 'SET_CREATE_ERROR';
export const setCreateError = () => action(SET_CREATE_ERROR);

export const CLEAR_CREATE_ERROR = 'CLEAR_CREATE_ERROR';
export const clearCreateError = () => action(CLEAR_CREATE_ERROR);

export const DOWNLOAD_BO_USER_EXPORT = createRequestTypes('DOWNLOAD_BO_USER_EXPORT');
export const downloadBackofficeUserExport = {
  request: id => action(DOWNLOAD_BO_USER_EXPORT[REQUEST], id),
  success: data => action(DOWNLOAD_BO_USER_EXPORT[SUCCESS], { data }),
  failure: error => action(DOWNLOAD_BO_USER_EXPORT[FAILURE], { error }),
};
