import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { DefaultButton } from 'common/components';
import AccountTable from '../AccountTable';
import NoAccountsPanel from '../NoAccountsPanel';

import { staticStyles } from './style';

const AccountsBlock = ({
  captionId,
  buttonId,
  noAccCaptionId,
  data,
  dontShowTrade,
  onDepositClick,
  onWithdrawClick,
  paymentButtons,
  isDemo,
  ...props
}) => (
  <div className="AccountsBlock">
    <div className="AccountsBlock__head">
      <FormattedMessage id={captionId}>{txt => <h3>{txt}</h3>}</FormattedMessage>
      {!!data.length && props.onAddAccountClick && (
        <DefaultButton textId={buttonId} onClick={props.onAddAccountClick} filled={!isDemo} plus />
      )}
    </div>

    <div className="AccountsBlock__body">
      {!data.length && (
        <NoAccountsPanel captionId={noAccCaptionId} buttonId={buttonId} onAddAccountClick={props.onAddAccountClick} />
      )}

      {!!data.length && (
        <AccountTable
          onDepositClick={onDepositClick}
          onWithdrawClick={onWithdrawClick}
          data={data}
          webTerminal={!dontShowTrade && data.some(item => item.webTerminal !== null)}
          paymentButtons={paymentButtons}
          onRowClick={props.onAccountClick}
        />
      )}
    </div>

    <style jsx>{staticStyles}</style>
  </div>
);

AccountsBlock.propTypes = {
  captionId: PropTypes.string.isRequired,
  buttonId: PropTypes.string,
  noAccCaptionId: PropTypes.string.isRequired,
  data: PropTypes.array,
  onAccountClick: PropTypes.func.isRequired,
  onAddAccountClick: PropTypes.func,
  onDepositClick: PropTypes.func,
  dontShowTrade: PropTypes.bool,
  onWithdrawClick: PropTypes.func,
  paymentButtons: PropTypes.bool,
  isDemo: PropTypes.bool,
};

AccountsBlock.defaultProps = {
  buttonId: '',
  data: [],
  onAddAccountClick: null,
  onDepositClick: null,
  dontShowTrade: false,
  onWithdrawClick: null,
  paymentButtons: true,
  isDemo: false,
};

export default AccountsBlock;
export { AccountsBlock };
