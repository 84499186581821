import React from 'react';
import PropTypes from 'prop-types';

import { withTheme } from 'common/styling/theme';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { staticStyles, getDynamicStyles } from './style';

const StrategyInfo = ({ cells, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const {
    justMonthlyYield,
    justYield,
    justFeeRate,
    investBalanceOperations,
    investTradersFee,
    ageAndWeeks,
    justInvestorsCount,
  } = cells;
  const justInvestorsCountNew = 'justInvestorsCount';

  return (
    <div className="StrategyInfo">
      <div className="StrategyInfo__title">
        <FormattedMessage id="strategyInformation">{txt => txt}</FormattedMessage>
      </div>
      <div className="StrategyInfo__info-wrap">
        <div className="StrategyInfo__info-left">
          {/* Monthly yield */}
          <div className="StrategyInfo__info-item">
            <div className="StrategyInfo__info-item-title">
              <FormattedMessage id="justMonthlyYield">{txt => txt}</FormattedMessage>
            </div>
            <div
              className={classNames('StrategyInfo__info-item-value Monthly-yield', {
                negative: justMonthlyYield?.value < 0,
              })}>
              {justMonthlyYield?.displayValue}
            </div>
          </div>

          {/* Yield */}
          <div className="StrategyInfo__info-item">
            <div className="StrategyInfo__info-item-title">
              <FormattedMessage id="justYield">{txt => txt}</FormattedMessage>
            </div>
            <div
              className={classNames('StrategyInfo__info-item-value Yield', {
                negative: justYield?.value < 0,
              })}>
              {justYield?.displayValue}
            </div>
          </div>

          {/* Fee rate */}
          <div className="StrategyInfo__info-item">
            <div className="StrategyInfo__info-item-title">
              <FormattedMessage id="justFeeRate">{txt => txt}</FormattedMessage>
            </div>
            <div className="StrategyInfo__info-item-value">{justFeeRate?.displayValue}</div>
          </div>

          {/* Age, weeks */}
          <div className="StrategyInfo__info-item">
            <div className="StrategyInfo__info-item-title">
              <FormattedMessage id="ageAndWeeks">{txt => txt}</FormattedMessage>
            </div>
            <div className="StrategyInfo__info-item-value">{ageAndWeeks?.displayValue}</div>
          </div>

          {/* Investors count */}
          <div className="StrategyInfo__info-item">
            <div className="StrategyInfo__info-item-title">
              <FormattedMessage id={justInvestorsCountNew}>{txt => txt}</FormattedMessage>
            </div>
            <div className="StrategyInfo__info-item-value">{justInvestorsCount?.displayValue}</div>
          </div>
        </div>

        <div className="StrategyInfo__info-right">
          {/* Balance operations */}
          <div className="StrategyInfo__info-item">
            <div className="StrategyInfo__info-item-title">
              <FormattedMessage id="investBalanceOperations">{txt => txt}</FormattedMessage>
            </div>
            <div className="StrategyInfo__info-item-value">{investBalanceOperations?.displayValue}</div>
          </div>

          {/* Trader’s fee */}
          <div className="StrategyInfo__info-item">
            <div className="StrategyInfo__info-item-title">
              <FormattedMessage id="investTradersFee">{txt => txt}</FormattedMessage>
            </div>
            <div className="StrategyInfo__info-item-value">{investTradersFee?.displayValue}</div>
          </div>
        </div>
      </div>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

StrategyInfo.propTypes = {
  cells: PropTypes.object,
  theme: PropTypes.object,
};

StrategyInfo.defaultProps = {
  cells: {},
  theme: {},
};

export default withTheme()(StrategyInfo);
