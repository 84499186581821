import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import queryString from 'query-string';

import { PageLayout, NoItemsPanel, PageContentPreloader, SearchPanel, BaseSelectField } from 'common/components';
import { withTheme } from 'common/styling/theme';
import noPartnersImage from 'common/images/noPartners.svg';

import { createPartnerAccountsExport } from 'backoffice/partnership/_redux';

import { getPartners } from '../../../_redux';
import { PartnersTable } from '../../_components';

import { staticStyles, getDynamicStyles } from './style';

class PartnersPage extends React.Component {
  query = queryString.parse(this.props.location.search);

  constructor(props) {
    super(props);
    this.query.page_size = this.query.page_size ? this.query.page_size : 10;
    props.getPartners({ search: this.query });
  }

  componentDidUpdate({ isPartnerAccountsExportRedirect }) {
    if (this.props.isPartnerAccountsExportRedirect !== isPartnerAccountsExportRedirect) {
      this.props.history.push('/backoffice/partners/exports');
    }
  }

  handleGetPartners = () => {
    this.query.page_size = this.query.page_size ? this.query.page_size : 10;
    this.props.getPartners({ search: this.query });
  };

  render() {
    const { partners, getPartners, createPartnerAccountsExport, partnersAreLoaded, history, location, theme } =
      this.props;

    const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
    const query = queryString.parse(location.search);
    const handleRowClick = (_rowId, rowInfo) => {
      history.push(`/backoffice/users/${rowInfo.original.id}`);
    };
    const inputs = [
      {
        title: 'justMasterIb',
        accessor: 'master_ib',
      },
    ];

    return (
      <PageLayout
        captionSlug="boPartners"
        showReload
        onReloadClick={this.handleGetPartners}
        reloading={!partnersAreLoaded}>
        <PageContentPreloader ready={partnersAreLoaded} firstLaunchOnly>
          {!partners.count && !Object.keys(query).length ? (
            ''
          ) : (
            <SearchPanel
              location={location}
              history={history}
              getList={getPartners}
              params={{ search: query }}
              inputs={inputs}
            />
          )}
          {partners.count ? (
            <>
              <div className="PartnersPage__export-select">
                <BaseSelectField
                  name="import"
                  textId="boExportPartners"
                  options={[
                    { value: 'showExports', label: 'Show exports' },
                    { value: 'xlsx', label: 'Export in xlsx' },
                    { value: 'csv', label: 'Export in csv' },
                  ]}
                  onChange={option => {
                    if (option.value === 'showExports') {
                      history.push('/backoffice/partners/exports');
                    } else {
                      createPartnerAccountsExport(option.value);
                    }
                  }}
                  maxMenuHeight={170}
                />
              </div>
              <PartnersTable
                data={partners}
                loading={!partnersAreLoaded}
                location={location}
                history={history}
                getList={getPartners}
                params={{ search: query }}
                onRowClick={handleRowClick}
              />
            </>
          ) : (
            <NoItemsPanel
              icon={noPartnersImage}
              captionSlug={Object.keys(query).length ? 'noPartnersMatchingTheParameters' : 'noPartnersYet'}
            />
          )}
          <style jsx>{staticStyles}</style>
          <style jsx>{dynamicStyles}</style>
        </PageContentPreloader>
      </PageLayout>
    );
  }
}

PartnersPage.propTypes = {
  location: PropTypes.object.isRequired,
  getPartners: PropTypes.func.isRequired,
  createPartnerAccountsExport: PropTypes.func.isRequired,
  partners: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  partnersAreLoaded: PropTypes.bool.isRequired,
  isPartnerAccountsExportRedirect: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

PartnersPage.defaultProps = {
  theme: {},
};

export default compose(
  withTheme(),
  connect(
    state => ({
      partners: state.backoffice.common.partners,
      partnersAreLoaded: state.backoffice.common.partnersAreLoaded,
      isPartnerAccountsExportRedirect: state.backoffice.partnership.isPartnerAccountsExportRedirect,
    }),
    {
      getPartners: search => getPartners.request(search),
      createPartnerAccountsExport: ext => createPartnerAccountsExport.request(ext),
    }
  )
)(PartnersPage);
export { PartnersPage };
