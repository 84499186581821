import React, { useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { StatusCell, PaginationTable, DateCell, DefaultButton, BaseSelectField } from 'common/components';

import { withTheme } from 'common/styling/theme';

import { createBackOfficePaymentExport } from '../../_redux';

import { staticStyles, getDynamicStyles } from './style';

const PaymentsTable = ({
  data,
  allowManagePaymentsExports,
  exportButtonCaptionSlug,
  isExportPaymentBeingCreated,
  createBackOfficePaymentExport,
  onRowClick,
  getList,
  loading,
  history,
  type,
  location,
  params,
  theme,
  intl,
  hasPaymentOperationManagers,
}) => {
  const pushToExportsFunc = useCallback(() => history.push(`/backoffice/${type}/exports`), [type]);
  const exportsType = useMemo(() => {
    if (type === 'deposits') {
      return 'deposit_export';
    }
    return 'withdraw_export';
  }, []);

  /* eslint-disable */

  const columns = [
    {
      key: 'boId',
      accessor: 'id',
      Cell: ({ original: { id } }) => <span className="id">{id}</span>,
    },
    {
      key: 'justEmail',
      accessor: 'user__email',
      Cell: ({ original: { user } }) => <span>{user.email}</span>,
      isPreviewCell: true,
    },
    {
      key: 'boAccount',
      accessor: 'account',
      Cell: ({ original: { account } }) => (
        <span className="account">{account?.login ?? intl.formatMessage({ id: 'boWallet' })}</span>
      ),
    },
    {
      key: 'boAmount',
      Cell: ({ original: { amount } }) => (
        <span className="amount">
          {amount.amount} {amount.currency}
        </span>
      ),
      isPreviewCell: true,
    },
    {
      key: 'justCommission',
      Cell: ({ original: { commission } }) => (
        <span className="commission">
          {commission?.amount} {commission?.currency}
        </span>
      ),
    },
    ...(hasPaymentOperationManagers
      ? [
          {
            key: 'justManager',
            Cell: ({ original: { manager } }) => (
              <div>{manager ? `${manager.firstName} ${manager.lastName}` : '-'}</div>
            ),
          },
        ]
      : []),
    {
      key: 'justConvertationCourse',
      Cell: ({ original: { exchangeString } }) => <span className="convertation-course">{exchangeString}</span>,
    },
    {
      key: 'boPaymentSystem',
      Cell: ({ original: { paymentSystemSlug } }) => <span className="payment-system-slug">{paymentSystemSlug}</span>,
    },
    {
      key: 'boPaymentMethod',
      Cell: ({ original: { paymentMethodTitle } }) => <span className="payment-method-slug">{paymentMethodTitle}</span>,
    },
    {
      key: 'boCreated',
      accessor: 'created',
      Cell: ({ original: { created } }) => <DateCell value={created} />,
      isPreviewCell: true,
    },
    {
      key: 'boStatus',
      accessor: '_status',
      Cell: ({ original: { status } }) => (
        <>
          <div className="status">
            <StatusCell statusCode={status} />
          </div>
        </>
      ),
      isPreviewCell: true,
    },
  ];
  /* eslint-enable */

  const rowProps = (state, rowInfo) => ({
    onClick: () => onRowClick(rowInfo.original.id),
  });

  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className="PaymentsTable">
      {allowManagePaymentsExports && exportButtonCaptionSlug && (
        <div className="PaymentsTable__export-buttons">
          <DefaultButton
            type="button"
            textId={exportButtonCaptionSlug}
            onClick={() => history.push(`/backoffice/${type}/exports`)}
          />
          <div className="PaymentsTable__export-buttons--select">
            <BaseSelectField
              name="import"
              textId="justExport"
              options={[
                { value: 'xlsx', label: 'Export in xlsx' },
                { value: 'csv', label: 'Export in csv' },
              ]}
              onChange={option => {
                createBackOfficePaymentExport({ exportsType, pushToExportsFunc, ext: option.value });
              }}
              disabled={isExportPaymentBeingCreated}
            />
          </div>
        </div>
      )}
      <PaginationTable
        data={data}
        loading={loading}
        columns={columns}
        getTrProps={rowProps}
        onRowClick={onRowClick}
        getList={getList}
        location={location}
        history={history}
        params={params}
        isResponsive
      />
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

PaymentsTable.propTypes = {
  onRowClick: PropTypes.func.isRequired,
  allowManagePaymentsExports: PropTypes.bool.isRequired,
  isExportPaymentBeingCreated: PropTypes.bool.isRequired,
  createBackOfficePaymentExport: PropTypes.func.isRequired,
  exportButtonCaptionSlug: PropTypes.string,
  data: PropTypes.object.isRequired,
  type: PropTypes.string,
  getList: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  hasPaymentOperationManagers: PropTypes.bool.isRequired,
  theme: PropTypes.object,
};

PaymentsTable.defaultProps = {
  exportButtonCaptionSlug: '',
  type: '',
  theme: {},
};

export default connect(
  state => ({
    isExportPaymentBeingCreated: state.backoffice.payments.isExportPaymentBeingCreated,
  }),
  {
    createBackOfficePaymentExport: ({ exportsType, pushToExportsFunc, ext }) =>
      createBackOfficePaymentExport.request({ exportsType, pushToExportsFunc, ext }),
  }
)(injectIntl(withTheme()(PaymentsTable)));
export { PaymentsTable };
