import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTheme, taggedStylesToString } from 'common/styling/theme';

import { Tooltip } from 'common/components';

import { getCrmPlanOverdue, getCrmPlanToday, getCrmPlanTomorrow } from '../../api';

import { staticStyles, getDynamicStyles } from './style';

const TasksData = ({ theme }) => {
  const dynamicStyles = Object.keys(theme).length ? taggedStylesToString(getDynamicStyles(theme)) : ` `;

  const history = useHistory();

  const [tasks, setTasks] = useState({ overdue: 0, today: 0, tomorrow: 0 });

  useEffect(() => {
    const search = { page: 1, page_size: 10 };
    // TODO: ПЕРЕДЕЛАТЬ НА ОТДЕЛЬНЫЙ ЗАПРОС
    Promise.all([getCrmPlanOverdue(search), getCrmPlanToday(search), getCrmPlanTomorrow(search)])
      .then(data => {
        data.forEach(({ data: { count } }, ind) => {
          if (ind === 0) {
            setTasks(prevState => ({ ...prevState, overdue: count }));
          }
          if (ind === 1) {
            setTasks(prevState => ({ ...prevState, today: count }));
          }
          if (ind === 2) {
            setTasks(prevState => ({ ...prevState, tomorrow: count }));
          }
        });
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <button type="button" className="TasksData" onClick={() => history.push('/crm/tasks/')}>
      <Tooltip id="menuTasksSuccessTooltip" textId="justTodayTasks" place="bottom">
        <div className="TasksData__item success">{tasks.today}</div>
      </Tooltip>
      <Tooltip id="menuTasksTomorrowTooltip" textId="justTomorrowTasks" place="bottom">
        <div className="TasksData__item">{tasks.tomorrow}</div>
      </Tooltip>
      <Tooltip id="menuTasksOverdueTooltip" textId="justOverdueTasks" place="bottom">
        <div className="TasksData__item overdue">{tasks.overdue}</div>
      </Tooltip>
      <style jsx global>
        {staticStyles}
      </style>
      <style>{dynamicStyles}</style>
    </button>
  );
};

TasksData.propTypes = {
  theme: PropTypes.object,
};

TasksData.defaultProps = {
  theme: {},
};

export default withTheme()(
  connect(state => ({
    plans: state.crm.tasks.plans,
    plansAreLoaded: state.crm.tasks.plansAreLoaded,
  }))(TasksData)
);
