import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTheme } from 'common/styling/theme';

import { staticStyles } from './style';

const Brokeree = ({ id, accountTypeId, brokereeConfig }) => {
  const [brokereeLink, setBrokereeLink] = useState(null);
  useEffect(() => {
    if (brokereeConfig.manager_types[accountTypeId]) {
      axios
        .post('/utils/brokeree/login/', { login: id, accountTypeId, userType: 'Manager' })
        .then(({ data: tokenResponse }) => {
          setBrokereeLink(tokenResponse.link);
        })
        .catch(() => {
          setBrokereeLink(brokereeConfig.app_link);
        });
    } else if (brokereeConfig.investor_types[accountTypeId]) {
      axios
        .post('/utils/brokeree/login/', {
          login: id,
          accountTypeId,
          userType: 'Investor',
        })
        .then(({ data: tokenResponse }) => setBrokereeLink(tokenResponse.link))
        .catch(() => {
          setBrokereeLink(brokereeConfig.app_link);
        });
    }
  }, []);

  return (
    <div className="AccountPage__Brokeree">
      {brokereeLink && (
        <iframe frameBorder="0" title="brokeree-iframe" style={{ width: '100%', height: '800px' }} src={brokereeLink} />
      )}
      <style jsx>{staticStyles}</style>
    </div>
  );
};

export default compose(
  withTheme(),
  connect(state => ({
    brokereeConfig: state.interfaceConfig.brokereeConfig,
  }))
)(Brokeree);
export { Brokeree };
