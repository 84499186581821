import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  BasicModal,
  NoItemsPanel,
  PageLayout,
  PageContentPreloader,
  DefaultButton,
  BaseSelectField,
} from 'common/components';
import { withTheme } from 'common/styling/theme';
import noPartnersImage from 'common/images/noPartners.svg';
import { getCommissionsList, getRewardFrequencies, createPartnerCommissionsExport } from '../../_redux';
import {
  CommissionsFilterForm,
  CommissionsTable,
  EditCommissionForm,
  PayCommissionForm,
  RejectRewardsForm,
} from '../../_components';

import { staticStyles, getDynamicStyles } from './style';

const CommissionsPage = ({
  filter,
  commissionsList,
  commissionsListIsLoaded,
  rewardFrequencies,
  rewardFrequenciesAreLoaded,
  getRewardFrequencies,
  getCommissionsList,
  createPartnerCommissionsExport,
  isPartnerCommissionsExportRedirect,
  theme,
  history,
  location,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const query = { page: 1, page_size: 10, ...queryString.parse(location.search) };
  const [isEditCommissionModalOpen, setEditCommissionModalOpen] = useState(false);
  const [isPayCommissionModalOpen, setPayCommissionModalOpen] = useState(false);
  const [isRejectRewardsModalOpen, setRejectRewardsModalOpen] = useState(false);
  const [rewardId, setRewardId] = useState(0);
  const [selectedRecords, setSelectedRecords] = useState([]);

  useEffect(() => {
    getRewardFrequencies();
  }, [getRewardFrequencies]);

  useEffect(() => {
    if (isPartnerCommissionsExportRedirect) history.push('/backoffice/partners/commissions/exports');
  }, [isPartnerCommissionsExportRedirect, history]);

  const toggleEditCommissionModal = () => {
    setEditCommissionModalOpen(!isEditCommissionModalOpen);
  };

  const togglePayCommissionModal = () => {
    setPayCommissionModalOpen(!isPayCommissionModalOpen);
  };

  const toggleRejectRewardsModal = () => {
    setRejectRewardsModalOpen(!isRejectRewardsModalOpen);
  };

  const onEditRecord = id => {
    setRewardId(id);
    toggleEditCommissionModal();
  };

  return (
    <PageLayout captionSlug="boCommissions">
      <PageContentPreloader ready={rewardFrequenciesAreLoaded} type="bigLine">
        <CommissionsFilterForm search={query} rewardFrequencies={rewardFrequencies} />
        {!!commissionsList.count && (
          <>
            <div className="CommissionsPage__export-select">
              <BaseSelectField
                name="import"
                textId="boCommissionsExport"
                options={[
                  { value: 'showExports', label: 'Show exports' },
                  { value: 'xlsx', label: 'Export in xlsx' },
                  { value: 'csv', label: 'Export in csv' },
                ]}
                onChange={option => {
                  if (option.value === 'showExports') {
                    history.push('/backoffice/partners/commissions/exports');
                  } else {
                    createPartnerCommissionsExport(option.value);
                  }
                }}
                maxMenuHeight={170}
              />
            </div>
            <CommissionsTable
              data={commissionsList}
              loading={!commissionsListIsLoaded}
              location={location}
              history={history}
              getList={query => getCommissionsList({ ...filter, ...query.search })}
              params={{ search: query }}
              onRowClick={() => {}}
              onEditRecord={onEditRecord}
              selectedRecords={selectedRecords}
              setSelectedRecords={setSelectedRecords}
            />
            <div className="CommissionsPage__buttons">
              <DefaultButton
                onClick={togglePayCommissionModal}
                textId="justReleaseRewards"
                disabled={!selectedRecords.length}
                filled
              />
              <DefaultButton
                onClick={toggleRejectRewardsModal}
                textId="justRejectRewards"
                disabled={!selectedRecords.length}
              />
            </div>
          </>
        )}
        {Object.entries(filter).length !== 0 && commissionsList.count === 0 && (
          <NoItemsPanel
            icon={noPartnersImage}
            captionSlug={Object.keys(query).length ? 'noRewardsMatchingTheParameters' : 'noRewardsYet'}
          />
        )}
      </PageContentPreloader>
      <BasicModal
        isOpen={isEditCommissionModalOpen}
        captionId="justCommissionInfo"
        onRequestClose={toggleEditCommissionModal}>
        <EditCommissionForm
          commission={commissionsList.results && commissionsList.results.find(item => item.id === rewardId)}
          toggleEditCommissionModal={toggleEditCommissionModal}
          getList={() => getCommissionsList({ ...filter, ...query })}
        />
      </BasicModal>
      <BasicModal
        isOpen={isPayCommissionModalOpen}
        captionId="justPayRewards"
        onRequestClose={togglePayCommissionModal}>
        <PayCommissionForm
          selectedRecords={selectedRecords}
          setSelectedRecords={setSelectedRecords}
          filter={{ ...filter, ...query }}
          togglePayCommissionModal={togglePayCommissionModal}
        />
      </BasicModal>
      <BasicModal
        isOpen={isRejectRewardsModalOpen}
        captionId="justRejectRewards"
        onRequestClose={toggleRejectRewardsModal}>
        <RejectRewardsForm
          selectedRecords={selectedRecords}
          setSelectedRecords={setSelectedRecords}
          filter={{ ...filter, ...query }}
          toggleRejectRewardsModal={toggleRejectRewardsModal}
        />
      </BasicModal>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </PageLayout>
  );
};

CommissionsPage.propTypes = {
  commissionsList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  commissionsListIsLoaded: PropTypes.bool.isRequired,
  isPartnerCommissionsExportRedirect: PropTypes.bool.isRequired,
  rewardFrequencies: PropTypes.array.isRequired,
  rewardFrequenciesAreLoaded: PropTypes.bool.isRequired,
  getCommissionsList: PropTypes.func.isRequired,
  getRewardFrequencies: PropTypes.func.isRequired,
  createPartnerCommissionsExport: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  filter: PropTypes.object,
  theme: PropTypes.object,
};

CommissionsPage.defaultProps = {
  filter: {},
  theme: {},
};

export default compose(
  withRouter,
  withTheme(),
  connect(
    state => ({
      filter: state.form.CommissionsFilterForm && state.form.CommissionsFilterForm.values,
      commissionsList: state.backoffice.partnership.commissionsList,
      commissionsListIsLoaded: state.backoffice.partnership.commissionsListIsLoaded,
      rewardFrequencies: state.backoffice.partnership.rewardFrequencies,
      rewardFrequenciesAreLoaded: state.backoffice.partnership.rewardFrequenciesAreLoaded,
      isPartnerCommissionsExportRedirect: state.backoffice.partnership.isPartnerCommissionsExportRedirect,
    }),
    {
      getCommissionsList: data => getCommissionsList.request(data),
      getRewardFrequencies: () => getRewardFrequencies.request(),
      createPartnerCommissionsExport: ext => createPartnerCommissionsExport.request(ext),
    }
  )
)(CommissionsPage);
export { CommissionsPage };
