import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { required } from 'redux-form-validators';
import { FormattedMessage } from 'react-intl';

import { DefaultButton, RadioButton, ReduxTextField, ReduxSelectField } from 'common/components';

import { withdrawInvestmentsNew, closeInvestmentNew } from 'client/investNew/_redux/actions';
import staticStyles from './style';

const InvestWithdrawFormNew = ({
  accounts,
  isWallet,
  toggleModal,
  handleSubmit,
  strategyId,
  dispatch,
  submitting,
  invalid,
  error,
}) => {
  const [withdrawType, setWithdrawType] = useState('part');
  const [selectedAccountCurrency, setSelectedAccountCurrency] = useState('');

  const submit = handleSubmit(values => {
    if (withdrawType === 'part') {
      return withdrawInvestmentsNew(
        {
          strategy: strategyId,
          account: values.account,
          amount: { amount: values.amount, currency: selectedAccountCurrency || 'USD' },
        },
        dispatch
      );
    }
    return closeInvestmentNew(
      {
        strategy: strategyId,
        account: values.account,
      },
      dispatch
    );
  });

  const accountsOptions = accounts.map(account => ({
    value: account.id,
    label: `${account.login} (${account.balance.amount} ${account.balance.currency})`,
  }));

  const handleChangeAccount = value => {
    const selectedAccount = accounts.find(account => account.id === value);
    setSelectedAccountCurrency(selectedAccount.balance.currency);
  };

  return (
    <div className="Modal__content">
      <form className="InvestWithdrawForm" onSubmit={submit}>
        <FormattedMessage id="rammWithdraWantText" />
        <div className="InvestWithdrawForm__inputs-inner">
          {!isWallet && (
            <Field
              component={ReduxSelectField}
              name="account"
              maxMenuHeight={140}
              textId="justAccount"
              options={accountsOptions}
              disabled={submitting}
              validate={[required()]}
              onChange={handleChangeAccount}
              searchable
            />
          )}
          <div>
            <RadioButton
              name="withdrawType"
              textId="rammWithdrawPartOfMoney"
              value="part"
              onChange={() => setWithdrawType('part')}
              checked={withdrawType === 'part'}
            />
            <div className="InvestWithdrawForm__field">
              <Field
                component={ReduxTextField}
                name="amount"
                textId="justWithdrawAmount"
                type="number"
                validate={withdrawType === 'part' ? [required()] : []}
                disabled={submitting}
                description={isWallet ? 'USD' : selectedAccountCurrency}
              />
            </div>
          </div>

          <div>
            <RadioButton
              name="withdrawType"
              textId="justCloseInvestmentIntoStrategyAndWithdrawMoneyToWallet"
              value="all"
              onChange={() => setWithdrawType('all')}
              checked={withdrawType === 'all'}
            />
            <p style={{ visibility: 'hidden' }} className="InvestWithdrawForm__sub">
              <FormattedMessage id="rammWithdrawCloseStrategyDesc" />
            </p>
          </div>

          {error && <strong className="formError">{error}</strong>}
        </div>
        <div className="InvestWithdrawForm__btns">
          <DefaultButton
            textId={withdrawType === 'part' ? 'justWithdraw' : 'rammWithdrawCloseStrategy'}
            type="submit"
            loading={submitting}
            disabled={invalid || submitting}
            filled
          />

          <DefaultButton textId="justCancel" type="button" onClick={() => toggleModal()} />
        </div>
        <style jsx>{staticStyles}</style>
      </form>
    </div>
  );
};

InvestWithdrawFormNew.propTypes = {
  accounts: PropTypes.array.isRequired,
  isWallet: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

InvestWithdrawFormNew.defaultProps = {
  error: '',
};

export default compose(
  reduxForm({
    form: 'InvestWithdrawFormNew',
    onSubmitSuccess: (result, dispatch, props) => {
      props.toggleModal();
    },
  })
)(InvestWithdrawFormNew);
export { InvestWithdrawFormNew };
