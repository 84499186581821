import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { VerificationPanel, DocumentsForm, SumSub, ZignSec } from '../../_components';

const DocumentsPage = ({ kycVerifier, status, documents }) => (
  <VerificationPanel captionId="justDocuments" warningMessageTextId="kycDocumentsMessage">
    {kycVerifier === 'sumsub' && <SumSub />}
    {kycVerifier === 'default' && <DocumentsForm documents={documents} profileStatus={status} />}
    {kycVerifier === 'zignsec' && <ZignSec />}
  </VerificationPanel>
);

DocumentsPage.propTypes = {
  kycVerifier: PropTypes.string.isRequired,
  documents: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
};

export default connect(state => {
  const kycVerifier = state.interfaceConfig.kycSettings.kyc_verifier;
  const {
    status,
    passportStatus,
    identificationProofStatus,
    addressProofStatus,
    passportWithFaceStatus,
    additionalDocumentStatus,
  } = state.user.profile;
  return {
    kycVerifier,
    status,
    documents: {
      passportStatus,
      identificationProofStatus,
      addressProofStatus,
      passportWithFaceStatus,
      additionalDocumentStatus,
    },
  };
})(DocumentsPage);
export { DocumentsPage };
