import axios from 'axios';

export const getCommissionsList = search =>
  axios.get(`/backoffice/partnership/systems/commissions/`, { params: { ...search } });

export const editCommission = (commissionId, amount) =>
  axios.put(`/backoffice/partnership/systems/commissions/${commissionId}/`, { amount }, { validateStatus: false });

export const payCommissions = payload => axios.post(`/backoffice/partnership/systems/commissions/pay/`, { ...payload });

export const rejectRewards = payload =>
  axios.post(`/backoffice/partnership/systems/commissions/reject/`, { ...payload });

export const getRewardFrequencies = () => axios.get(`/backoffice/partnership/systems/account/freq_of_payments/`);

export const boGetPartnershipRequests = search =>
  axios.get('/backoffice/partnership/requests/', { params: { ...search } });

export const boGetPartnershipRequest = requestId => axios.get(`/backoffice/partnership/requests/${requestId}`);

export const createPartnerAccountsExport = ext => axios.post('/reports/partner_accounts_export/', { ext });
export const getPartnerAccountsExports = search =>
  axios.get('/reports/partner_accounts_export/', { params: { ...search } });
export const downloadPartnerAccountsExport = (id, name) =>
  axios.get(`/reports/partner_accounts_export/${id}/`, { params: { name }, responseType: 'blob' });

export const createPartnerCommissionExport = ext => axios.post('/reports/commission_export/', { ext });
export const getPartnerCommissionExports = search =>
  axios.get('/reports/commission_export/', { params: { ...search } });
export const downloadPartnerCommissionExport = (id, name) =>
  axios.get(`/reports/commission_export/${id}/`, { params: { name }, responseType: 'blob' });
