import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getOuterVerifyExternalLink } from 'client/accounts/_redux';

const ZignSec = ({ outerVerifyExternalLink, getOuterVerifyExternalLink }) => {
  useEffect(() => {
    getOuterVerifyExternalLink('zignsec');
  }, []);
  if (outerVerifyExternalLink) {
    return <iframe frameBorder="0" title="zignsec" src={outerVerifyExternalLink.url} width="100%" height="900px" />;
  }
  return null;
};

export default connect(state => ({ outerVerifyExternalLink: state.client.accounts.outerVerifyExternalLink }), {
  getOuterVerifyExternalLink: program => getOuterVerifyExternalLink.request({ program }),
})(ZignSec);
