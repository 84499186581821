import moment from 'moment';

function getCurrentDayRange() {
  const today = new Date();
  const startOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0);
  const endOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999);

  return [startOfDay.getTime(), endOfDay.getTime()];
}

function getYesterdayRange() {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const startOfDay = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate(), 0, 0, 0, 0);
  const endOfDay = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate(), 23, 59, 59, 999);

  return [startOfDay.getTime(), endOfDay.getTime()];
}

function getLastThreeDaysRange() {
  const today = new Date();
  const threeDaysAgo = new Date(today);
  threeDaysAgo.setDate(today.getDate() - 3);

  const startOfDay = new Date(threeDaysAgo.getFullYear(), threeDaysAgo.getMonth(), threeDaysAgo.getDate(), 0, 0, 0, 0);
  const endOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999);

  return [startOfDay.getTime(), endOfDay.getTime()];
}

function getLastSevenDaysRange() {
  const today = new Date();
  const sevenDaysAgo = new Date(today);
  sevenDaysAgo.setDate(today.getDate() - 7);

  const startOfDay = new Date(sevenDaysAgo.getFullYear(), sevenDaysAgo.getMonth(), sevenDaysAgo.getDate(), 0, 0, 0, 0);
  const endOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999);

  return [startOfDay.getTime(), endOfDay.getTime()];
}

function getLastThirtyDaysRange() {
  const today = new Date();
  const thirtyDaysAgo = new Date(today);
  thirtyDaysAgo.setDate(today.getDate() - 30);

  const startOfDay = new Date(
    thirtyDaysAgo.getFullYear(),
    thirtyDaysAgo.getMonth(),
    thirtyDaysAgo.getDate(),
    0,
    0,
    0,
    0
  );
  const endOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999);

  return [startOfDay.getTime(), endOfDay.getTime()];
}

// Function to get the range for the last month
function getPreviousMonthRange() {
  const today = new Date();

  // Calculate the first day of the previous month
  const firstDayOfPreviousMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);

  // Calculate the last day of the previous month
  const lastDayOfPreviousMonth = new Date(today.getFullYear(), today.getMonth(), 0);

  return [firstDayOfPreviousMonth.getTime(), lastDayOfPreviousMonth.getTime()];
}

const defaultDates = selectedValue => {
  const defaultDatesArray = [
    { label: 'justCustomRange', computedLabel: '', value: [null, null], isSelected: false },
    { label: 'justAll', computedLabel: 'justAll', value: getCurrentDayRange(), isSelected: true },
    { label: 'justYesterday', computedLabel: 'justYesterday', value: getYesterdayRange(), isSelected: false },
    {
      label: 'justLastThreeDays',
      computedLabel: 'justLastThreeDays',
      value: getLastThreeDaysRange(),
      isSelected: false,
    },
    {
      label: 'justLastSevenDays',
      computedLabel: 'justLastSevenDays',
      value: getLastSevenDaysRange(),
      isSelected: false,
    },
    {
      label: 'justLastThirtyDays',
      computedLabel: 'justLastThirtyDays',
      value: getLastThirtyDaysRange(),
      isSelected: false,
    },
    { label: 'justLastMonth', computedLabel: 'justLastMonth', value: getPreviousMonthRange(), isSelected: false },
  ];

  if (selectedValue) {
    const selectedDateString = selectedValue.join('');

    const selectedDatesArray = defaultDatesArray.map(date => {
      const valueString = date.value.map(item => moment(item).format('YYYY-MM-DD')).join('');

      if (selectedDateString === valueString) {
        return { ...date, isSelected: true };
      }

      return { ...date, isSelected: false };
    });

    if (selectedDatesArray.every(date => !date.isSelected)) {
      selectedDatesArray[0] = { ...selectedDatesArray[0], value: selectedValue, isSelected: true };
    }

    return selectedDatesArray;
  }

  return defaultDatesArray;
};

const getNewChoiceValuesAndChoiceValuesAfterSearchIfNewValueIsAll = currentFilter => {
  const choiceValues = currentFilter.choiceValues.map(choiceValue => {
    if (choiceValue.value !== 'All') {
      return { ...choiceValue, isSelected: false };
    }

    return { ...choiceValue, isSelected: true };
  });

  const choiceValuesAfterSearch = currentFilter.choiceValuesAfterSearch.map(choiceValue => {
    if (choiceValue.value !== 'All') {
      return { ...choiceValue, isSelected: false };
    }

    return { ...choiceValue, isSelected: true };
  });

  return { choiceValues, choiceValuesAfterSearch };
};

const getNewChoiceValuesAndChoiceValuesAfterSearchIfNotAll = (currentFilter, newValue) => {
  const choiceValues = currentFilter.choiceValues.map(choiceValue => {
    if (choiceValue.value === newValue) {
      return { ...choiceValue, isSelected: !choiceValue.isSelected };
    }
    if (choiceValue.value === 'All') {
      return { ...choiceValue, isSelected: false };
    }
    return { ...choiceValue };
  });

  const choiceValuesAfterSearch = currentFilter.choiceValuesAfterSearch.map(choiceValue => {
    if (choiceValue.value === newValue) {
      return { ...choiceValue, isSelected: !choiceValue.isSelected };
    }
    if (choiceValue.value === 'All') {
      return { ...choiceValue, isSelected: false };
    }
    return { ...choiceValue };
  });

  return { choiceValues, choiceValuesAfterSearch };
};

const getNewChoiceValuesAndChoiceValuesAfterSearchIfNotAllAndTypeBoolean = (currentFilter, newValue) => {
  const choiceValues = currentFilter.choiceValues.map(choiceValue => {
    if (choiceValue.value === newValue) {
      return { ...choiceValue, isSelected: true };
    }
    return { ...choiceValue, isSelected: false };
  });

  const choiceValuesAfterSearch = currentFilter.choiceValuesAfterSearch.map(choiceValue => {
    if (choiceValue.value === newValue) {
      return { ...choiceValue, isSelected: true };
    }
    return { ...choiceValue, isSelected: false };
  });

  return { choiceValues, choiceValuesAfterSearch };
};

function processChoiceValues(obj, allParams) {
  const resultObj = {};

  Object.keys(obj).forEach(key => {
    const choiceValues = obj[key].choiceValues;
    const processedValues = [];
    const currentFilterSelectedValues = allParams[obj[key].query_param];

    if (Array.isArray(choiceValues) && choiceValues.length > 0) {
      if (typeof choiceValues[0] === 'object') {
        processedValues.push({
          name: 'justAll',
          value: 'All',
          isSelected: !currentFilterSelectedValues,
        });

        if (obj[key].inputType === 'inputBoolean') {
          processedValues.push({ name: 'justYes', value: 'True', isSelected: currentFilterSelectedValues === 'True' });
          processedValues.push({ name: 'justNo', value: 'False', isSelected: currentFilterSelectedValues === 'False' });
        } else {
          choiceValues.forEach(item => {
            if (!currentFilterSelectedValues) {
              processedValues.push({
                ...item,
                isSelected: false,
              });
            } else {
              processedValues.push({
                ...item,
                isSelected: currentFilterSelectedValues.includes(item.value.toString()),
              });
            }
          });
        }
      } else if (obj[key].inputType === 'inputDate') {
        resultObj[key] = {
          ...obj[key],
          choiceValues: defaultDates(currentFilterSelectedValues),
          isDisplayed: Boolean(currentFilterSelectedValues),
        };
        return;
      } else if (obj[key].inputType === 'inputRange') {
        resultObj[key] = {
          ...obj[key],
          choiceValues: obj[key].choiceValues,
          enteredValues: currentFilterSelectedValues ?? [],
          isDisplayed: Boolean(currentFilterSelectedValues),
        };
        return;
      } else {
        resultObj[key] = {
          ...obj[key],
          selectedValues: [],
          isDisplayed: Boolean(currentFilterSelectedValues),
        };
        return;
      }
    } else if (obj[key].inputType === 'inputStr') {
      let value = '';
      if (currentFilterSelectedValues) value += currentFilterSelectedValues;

      resultObj[key] = { ...obj[key], value, isDisplayed: Boolean(currentFilterSelectedValues) };
      return;
    }
    resultObj[key] = {
      ...obj[key],
      choiceValues: processedValues,
      choiceValuesAfterSearch: [...processedValues],
      isDisplayed: Boolean(currentFilterSelectedValues),
    };
  });

  return resultObj;
}

export {
  defaultDates,
  getNewChoiceValuesAndChoiceValuesAfterSearchIfNewValueIsAll,
  getNewChoiceValuesAndChoiceValuesAfterSearchIfNotAll,
  getNewChoiceValuesAndChoiceValuesAfterSearchIfNotAllAndTypeBoolean,
  processChoiceValues,
};
