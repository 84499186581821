/* eslint-disable camelcase */
import { SubmissionError } from 'redux-form';
import { call, fork, put, take, delay } from 'redux-saga/effects';
import { acceptError } from 'common/_redux';
import { REQUEST } from 'redux-config/reduxHelpers';
import { getTradingAccountListSaga, getAccountsPrefetchSaga } from '../../_redux/commonSagas/sagas';

import * as ActionTypes from './actions';
import * as api from '../api';

export function* getInvestmentsRequestsNewSaga({ search }) {
  try {
    const { status, data } = yield call(api.getInvestmentsRequestsNew, search);
    if (status < 300) {
      yield put(ActionTypes.getInvestmentsRequestsNew.success(data));
    } else {
      yield put(ActionTypes.getInvestmentsRequestsNew.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getInvestmentsRequestsNew.failure());
  }
}

export function* getInvestmentsRequestsNewWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_INVESTMENTS_REQUESTS_NEW[REQUEST]);
    yield call(getInvestmentsRequestsNewSaga, data);
  }
}

// FIXME: В payload саги еще и отадется тип
export function* getInvestmentsNewSaga({ is_active, page, page_size }) {
  try {
    const { status, data } = yield call(api.getInvestmentsNew, { is_active, page, page_size });
    if (status < 300) {
      yield put(ActionTypes.getInvestmentsNew.success(data));
    } else {
      yield put(ActionTypes.getInvestmentsNew.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getInvestmentsNew.failure());
  }
}

export function* getInvestmentsNewWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_INVESTMENTS_NEW[REQUEST]);
    yield call(getInvestmentsNewSaga, data);
  }
}
export function* retrieveInvestStrategyNewSaga({ strategyId }) {
  try {
    const { status, data } = yield call(api.retrieveInvestStrategyNew, strategyId);
    if (status < 300) {
      yield put(ActionTypes.retrieveInvestStrategyNew.success(data));
    } else {
      yield put(ActionTypes.retrieveInvestStrategyNew.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.retrieveInvestStrategyNew.failure());
    throw e;
  }
}

export function* retrieveInvestStrategyNewWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.RETRIEVE_INVEST_STRATEGY_NEW[REQUEST]);
    yield call(retrieveInvestStrategyNewSaga, data);
  }
}

export function* getInvestStrategyApproveNewSaga({ strategyId, search }) {
  try {
    const upadetedSearch = {
      ...search,
      page: search.page ? search.page : 1,
      page_size: search.page_size ? search.page_size : 100,
    };
    const { status, data } = yield call(api.getInvestStrategyApproveNew, strategyId, upadetedSearch);
    if (status < 300) {
      yield put(ActionTypes.getInvestStrategyApproveNew.success(data));
    } else {
      yield put(ActionTypes.getInvestStrategyApproveNew.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getInvestStrategyApproveNew.failure());
  }
}

export function* getInvestStrategyApproveNewWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_INVEST_STRATEGIES_APPROVE_NEW[REQUEST]);
    yield call(getInvestStrategyApproveNewSaga, data);
  }
}

export function* createInvestStrategyNewSaga({ ...values }) {
  try {
    const { data, status } = yield call(api.createInvestStrategyNew, {
      ...values,
    });
    if (status < 300) {
      yield put(ActionTypes.createInvestStrategyNew.success(data));
      yield call(getAccountsPrefetchSaga);
    } else {
      const error = data.error ? { _error: data.error } : data;
      yield put(ActionTypes.createInvestStrategyNew.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.createInvestStrategyNew.failure());
    throw e;
  }
}

export function* createInvestStrategyNewWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.createInvestStrategyNew.REQUEST);
    yield call(createInvestStrategyNewSaga, payload);
  }
}

export function* editInvestStrategyNewSaga({ data, strategyId }) {
  try {
    const { status, data: error } = yield call(api.editInvestStrategyNew, { strategyId, data });
    if (status < 300) {
      yield call(retrieveInvestStrategyNewSaga, { strategyId });
      yield put(ActionTypes.editInvestStrategyNew.success());
    } else {
      const formError = new SubmissionError(error);
      yield put(ActionTypes.editInvestStrategyNew.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e, true));
  }
}

export function* editInvestStrategyNewWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.editInvestStrategyNew.REQUEST);
    yield call(editInvestStrategyNewSaga, payload);
  }
}

export function* getMyInvestStrategiesNewSaga(payload) {
  try {
    const { status, data } = yield call(api.getMyInvestStrategiesNew, {
      is_active: payload.is_active,
      page: payload.page + 1,
      page_size: payload.page_size,
    });
    if (status < 300) {
      yield put(ActionTypes.getMyInvestStrategiesNew.success(data));
    } else {
      yield put(ActionTypes.getMyInvestStrategiesNew.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getMyInvestStrategiesNew.failure());
    throw e;
  }
}

export function* getMyInvestStrategiesNewWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_MY_INVEST_STRATEGIES_NEW[REQUEST]);
    yield call(getMyInvestStrategiesNewSaga, data);
  }
}

export function* getInvestStrategiesNewSaga({ search }) {
  try {
    const { status, data } = yield call(api.getInvestStrategiesNew, search);
    if (status < 300) {
      yield put(ActionTypes.getInvestStrategiesNew.success(data));
    } else {
      yield put(ActionTypes.getInvestStrategiesNew.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getInvestStrategiesNew.failure());
    throw e;
  }
}

export function* getInvestStrategiesNewWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_INVEST_STRATEGIES_NEW[REQUEST]);

    yield call(getInvestStrategiesNewSaga, data);
  }
}

export function* getStrategyInvestmentsNewSaga({ strategyId, search, is_active }) {
  try {
    const { status, data } = yield call(api.getStrategyInvestmentsNew, strategyId, {
      ...search,
      is_active,
    });
    if (status < 300) {
      yield put(ActionTypes.getStrategyInvestmentsNew.success(data));
    } else {
      yield put(ActionTypes.getStrategyInvestmentsNew.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getStrategyInvestmentsNew.failure());
  }
}

export function* getStrategyInvestmentsNewWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_STRATEGY_INVESTMENTS_NEW[REQUEST]);
    yield call(getStrategyInvestmentsNewSaga, data);
  }
}

export function* addStrategyInvestmentsNewSaga({ strategy, ...values }) {
  try {
    const { data, status } = yield call(api.addStrategyInvestmentsNew, {
      strategy,
      amount: values.money,
      account: values.account,
    });

    if (status < 300) {
      yield put(ActionTypes.addStrategyInvestmentsNew.success(data));
      yield call(getStrategyInvestmentsNewSaga, {
        strategyId: strategy,
        is_active: 'True',
        search: { page: 1, page_size: 10 },
      });
      yield call(getStrategyInvestmentsNewSaga, {
        strategyId: strategy,
        is_active: 'False',
        search: { page: 1, page_size: 10 },
      });
      yield call(getInvestStrategyApproveNewSaga, {
        strategyId: strategy,
        is_active: 'False',
        search: { page: 1, page_size: 10 },
      });
    } else {
      const error = data.detail || data.error || data.amount || '';
      yield put(ActionTypes.addStrategyInvestmentsNew.failure(new SubmissionError({ _error: error })));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.addStrategyInvestmentsNew.failure());
    throw e;
  }
}

export function* addStrategyInvestmentsNewWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.addStrategyInvestmentsNew.REQUEST);
    yield call(addStrategyInvestmentsNewSaga, payload);
  }
}

export function* setInvestmentsActionNewSaga({ investmentId, action, strategyId = null, amount }) {
  try {
    const { data, status } = yield call(api.setInvestmentsActionNew, investmentId, action, {
      strategy: strategyId,
      amount,
    });
    if (status < 300) {
      yield put(ActionTypes.setInvestmentsActionNew.success(data));
      if (strategyId) {
        yield call(getInvestStrategyApproveNewSaga, { strategyId, search: {} });
      }
      yield call(getInvestmentsNewSaga, { is_active: 'True', page: 1, page_size: 10 });
      yield call(getTradingAccountListSaga, false);
      yield call(getMyInvestStrategiesNewSaga, { is_active: 'True', page: 1, page_size: 10 });
    } else {
      const error = data?.error || data?.detail || '';
      yield put(acceptError(error, false));
      yield put(ActionTypes.setInvestmentsActionNew.failure());
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.setInvestmentsActionNew.failure());
    throw e;
  }
}

export function* setInvestmentsActionNewWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.SET_INVESTMENTS_ACTION_NEW[REQUEST]);
    yield call(setInvestmentsActionNewSaga, data);
  }
}

// new ->

export function* closeInvestmentRequestNewSaga({ requestId, search }) {
  try {
    const { data, status } = yield call(api.closeInvestmentRequestNew, requestId);

    if (status < 300) {
      yield put(ActionTypes.closeInvestmentRequestNew.success(data));
      yield call(getInvestmentsRequestsNewSaga, { search });
    } else {
      yield put(ActionTypes.closeInvestmentRequestNew.failure(new SubmissionError({ _error: data.error })));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.closeInvestmentRequestNew.failure());
    throw e;
  }
}

export function* closeInvestmentRequestNewWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.closeInvestmentRequestNew[REQUEST]);
    yield call(closeInvestmentRequestNewSaga, payload);
  }
}

export function* getInvestStatementNewSaga({ investmentId }) {
  try {
    const { status, data } = yield call(api.getInvestStatementNew, investmentId);
    if (status < 300) {
      yield put(ActionTypes.getInvestStatementNew.success(data));
    } else {
      yield put(ActionTypes.getInvestStatementNew.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getInvestStatementNew.failure());
  }
}

export function* getInvestStatementNewWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_INVEST_STATEMENT_NEW[REQUEST]);
    yield call(getInvestStatementNewSaga, data);
  }
}

export function* getInvestmentTradesNewSaga({ investmentId, tradeType = 'open', startDate, endDate }) {
  try {
    const trateTypeNew = tradeType === 'open' ? 'open' : 'closed';
    const { status, data } = yield call(api.getInvestmentTradesNew, investmentId, trateTypeNew, startDate, endDate);
    if (status < 300) {
      yield put(ActionTypes.getInvestmentTradesNew.success(data));
    } else {
      yield put(ActionTypes.getInvestmentTradesNew.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getInvestmentTradesNew.failure());
  }
}

export function* getInvestmentTradesNewWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_INVESTMENT_TRADES_NEW[REQUEST]);
    yield call(getInvestmentTradesNewSaga, data);
  }
}

export function* closeInvestmentNewSaga({ strategy, account }) {
  try {
    const { data, status } = yield call(api.closeInvestmentRequestNew, account, strategy);

    if (status < 300) {
      yield put(ActionTypes.closeInvestmentNew.success(data));
      yield delay(1000);
      yield call(getInvestmentsNewSaga, { is_active: 'True', page: 1, page_size: 10 });
    } else {
      yield put(ActionTypes.closeInvestmentNew.failure(new SubmissionError({ _error: data.error || data.detail })));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.closeInvestmentNew.failure());
    throw e;
  }
}

export function* closeInvestmentNewWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.closeInvestmentNew.REQUEST);
    yield call(closeInvestmentNewSaga, payload);
  }
}

export function* withdrawInvestmentsNewSaga({ strategy, amount, account }) {
  try {
    // FYI account может и не быть если wallet
    const { data, status } = yield call(api.withdrawInvestmentRequestNew, strategy, amount, account);

    if (status < 300) {
      yield put(ActionTypes.withdrawInvestmentsNew.success(data));
      yield delay(1000);
      yield call(getInvestmentsNewSaga, { is_active: true, page: 1, page_size: 10 });
    } else {
      const error = data.detail || data.error || data.amount || '';
      yield put(ActionTypes.withdrawInvestmentsNew.failure(new SubmissionError({ _error: error })));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.withdrawInvestmentsNew.failure());
    throw e;
  }
}

export function* withdrawInvestmentsNewWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.withdrawInvestmentsNew.REQUEST);
    yield call(withdrawInvestmentsNewSaga, payload);
  }
}

export function* setInvestmentsDepositNewSaga({ strategy, amount, account }) {
  try {
    // FYI account может и не быть если wallet
    const { data, status } = yield call(api.depositInvestmentRequestNew, strategy, amount, account);
    if (status < 300) {
      yield put(ActionTypes.setInvestmentsDepositNew.success(data));
      yield delay(1000);
      yield call(getMyInvestStrategiesNewSaga, { is_active: true, page: 1, page_size: 10 });
      yield call(getInvestmentsNewSaga, { is_active: true, page: 1, page_size: 10 });
      yield call(getTradingAccountListSaga, false);
    } else {
      const error = data.detail || data.error || data.amount || '';
      yield put(ActionTypes.setInvestmentsDepositNew.failure(new SubmissionError({ _error: error })));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.setInvestmentsDepositNew.failure());
    throw e;
  }
}

export function* setInvestmentsDepositNewWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.setInvestmentsDepositNew.REQUEST);
    yield call(setInvestmentsDepositNewSaga, payload);
  }
}

export function* getInvestYieldChartNewSaga({ strategyId }) {
  try {
    const { status, data } = yield call(api.getInvestYieldChartNew, strategyId);
    if (status < 300) {
      yield put(ActionTypes.getInvestYieldChartNew.success(data));
    } else {
      yield put(ActionTypes.getInvestYieldChartNew.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getInvestYieldChartNew.failure());
    throw e;
  }
}

export function* getInvestYieldChartNewWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_INVEST_YIELD_CHART_NEW[REQUEST]);
    yield call(getInvestYieldChartNewSaga, data);
  }
}

export function* setInvestStrategyActionNewSaga({ strategyId, action }) {
  try {
    const { data, status } = yield call(api.setInvestStrategyActionNew, strategyId, action);
    if (status < 300) {
      yield put(ActionTypes.setInvestStrategyActionNew.success(data));
      yield delay(1000);
      yield call(getMyInvestStrategiesNewSaga, { is_active: 1, page: 1, page_size: 10 });
    } else {
      const error = data?.error ?? '';
      yield put(acceptError(error, true));
      yield put(ActionTypes.setInvestStrategyActionNew.failure());
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.setInvestStrategyActionNew.failure());
    throw e;
  }
}

export function* setInvestStrategyActionNewWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.SET_INVEST_STRATEGY_ACTION_NEW[REQUEST]);
    yield call(setInvestStrategyActionNewSaga, data);
  }
}

export default [
  fork(setInvestStrategyActionNewWatcherSaga),
  fork(closeInvestmentNewWatcherSaga),
  fork(getInvestStatementNewWatcherSaga),
  fork(getInvestmentTradesNewWatcherSaga),
  fork(setInvestmentsDepositNewWatcherSaga),
  fork(withdrawInvestmentsNewWatcherSaga),
  fork(closeInvestmentRequestNewWatcherSaga),
  fork(getInvestmentsNewWatcherSaga),
  fork(editInvestStrategyNewWatcherSaga),
  fork(getInvestmentsRequestsNewWatcherSaga),
  fork(getInvestStrategyApproveNewWatcherSaga),
  fork(setInvestmentsActionNewWatcherSaga),
  fork(createInvestStrategyNewWatcherSaga),
  fork(getStrategyInvestmentsNewWatcherSaga),
  fork(retrieveInvestStrategyNewWatcherSaga),
  fork(addStrategyInvestmentsNewWatcherSaga),
  fork(getMyInvestStrategiesNewWatcherSaga),
  fork(getInvestStrategiesNewWatcherSaga),
  fork(getInvestYieldChartNewWatcherSaga),
];
