import React, { useState, useMemo, useEffect } from 'react';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import MediaQuery from 'react-responsive';

import { deleteCrmCustomFilter } from 'crm/contacts/_redux';
import {
  getCRMFiltersList,
  createCRMCustomFilter,
  setModifiedFastFilters,
  setCRMCustomFilter,
  setSelectedFastFilters,
} from 'crm/_redux/actions';
import queryString from 'query-string';

import {
  AddContact,
  BasicCRMModal,
  CustomFilterSelect,
  CRMModals,
  BaseButton,
  BaseStaticSelect,
  SearchBar,
} from 'crm/_components';

import { FilterCreate, FilterChoose } from 'crm/_newComponents';

import chooseFilter from './images/chooseFilter.svg';
import plus from './images/plus.svg';

import { staticStyles } from './style';

const ActionsTab = ({ areThereAnySelectedContacts, intl, history, location }) => {
  const [crmModalType, setCrmModalType] = useState(null);
  const [addContact, setAddContact] = useState(false);
  const query = useMemo(() => ({ page_size: 10, ...queryString.parse(location.search) }), [location.search]);
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isChooseOpen, setIsChooseOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const initialFilter = { value: '', label: intl.formatMessage({ id: 'crmCustomFilter' }) };
  const [filter, setFilter] = useState(initialFilter);
  const [filterId, setFilterId] = useState(null);
  const dispatch = useDispatch();
  const fastFilters = useSelector(state => state.crm.common.fastFilters);

  useEffect(() => {
    setFilterId(queryString.parse(location.search).filterId);
  }, [location.search]);

  const toggleModal = () => {
    setIsOpen(isOpen => !isOpen);
  };

  const handleResetFilter = () => {
    setFilter(initialFilter);
    dispatch(setModifiedFastFilters({}));
    dispatch(setCRMCustomFilter({}));

    const hiddenFilters = { ...fastFilters };
    Object.keys(hiddenFilters).forEach(filter => {
      hiddenFilters[filter].isDisplayed = false;
    });
    dispatch(setSelectedFastFilters(hiddenFilters));

    sessionStorage.clear('fastFiltersQuery');

    history.replace('/crm/contacts/');
  };

  const toggleChooseModal = () => {
    if (filterId) handleResetFilter();

    setIsChooseOpen(!isChooseOpen);
  };
  const toggleDeleteModal = id => {
    setDeleteId(id);
    setIsDeleteOpen(isEditOpen => !isEditOpen);
  };
  const deleteCustomFilter = id => {
    deleteCrmCustomFilter(id, dispatch);
  };

  const handleFilterChange = value => {
    if (value.value === 'create') {
      toggleModal();
    } else {
      setFilter(value);

      const search = queryString.stringify({
        ...query,
        filterId: value.value,
      });

      sessionStorage.setItem('fastFiltersQuery', search);

      history.replace({
        search,
      });
    }
  };

  const handleSearchChange = searchValue => {
    const search = queryString.stringify({
      ...query,
      search: searchValue,
    });

    sessionStorage.setItem('fastFiltersQuery', search);

    history.replace({
      search,
    });
  };

  const actionOptions = [
    {
      label: intl.formatMessage({ id: 'justReassign' }),
      value: 'reassign',
    },
    {
      label: intl.formatMessage({ id: 'reassignManager' }),
      value: 'reassignManager',
    },
    {
      label: intl.formatMessage({ id: 'setPartnerCode' }),
      value: 'setPartnerCode',
    },
    {
      label: intl.formatMessage({ id: 'saveAs' }),
      value: 'saveAs',
    },
  ];

  return (
    <div className="ActionsTab">
      <div className="ActionsTab__filters-left">
        <BaseStaticSelect
          labelTextId="justActions"
          options={actionOptions}
          onSelect={value => {
            setCrmModalType(value);
          }}
          isDisabled={!areThereAnySelectedContacts}
        />
        <SearchBar onChange={handleSearchChange} />
        <button className="ActionsTab__filter-choose" onClick={toggleChooseModal}>
          <img src={chooseFilter} alt="choose filter" />
        </button>
        <MediaQuery query="(max-width: 479px)">
          <div className="ActionsTab__contacts">
            <button className="ActionsTab__uploadContacts-mobile" onClick={() => setAddContact(true)}>
              <img src={plus} alt="plus" />
            </button>
          </div>
        </MediaQuery>
      </div>
      <div className="ActionsTab__filters-right">
        <div className="ActionsTab__filter-select">
          <CustomFilterSelect
            getFilters={getCRMFiltersList}
            filtersSelector={state => state.crm.common.filtersList}
            modifiedFiltersSelector={state => state.crm.common.modifiedFilters}
            onChange={handleFilterChange}
            toggleDeleteModal={toggleDeleteModal}
            onResetFilter={handleResetFilter}
            filter={filter}
            filterId={filterId}
          />
        </div>
        <MediaQuery query="(min-width: 479px)">
          <div className="ActionsTab__contacts">
            <BaseButton textId="justUploadContacts" onClick={() => setAddContact(true)} plus />
          </div>
        </MediaQuery>
      </div>
      <AddContact isOpen={addContact} onRequestClose={() => setAddContact(false)} />
      {crmModalType && <CRMModals modalType={crmModalType} onRequestClose={() => setCrmModalType(null)} />}
      <BasicCRMModal isOpen={isOpen} captionId="crmCustomFilter" onRequestClose={toggleModal}>
        <FilterCreate
          onRequestClose={toggleModal}
          modifiedFiltersSelector={state => state.crm.common.modifiedFilters}
          createCustomFilterSubmitFunc={createCRMCustomFilter}
        />
      </BasicCRMModal>
      <BasicCRMModal
        isOpen={isChooseOpen}
        onRequestClose={toggleChooseModal}
        captionId="crmChooseFiltersToDisplay"
        className="FilterChooseModal">
        <FilterChoose
          modifiedFiltersSelector={state => state.crm.common.modifiedFilters}
          onRequestClose={toggleChooseModal}
          setCustomFilter={setFilter}
        />
      </BasicCRMModal>
      <BasicCRMModal isOpen={isDeleteOpen} captionId="confirmText" onRequestClose={toggleDeleteModal}>
        <div className="ActionsTab__ModalButtons">
          <BaseButton
            color="white"
            textId="justCancel"
            onClick={() => {
              setIsDeleteOpen(false);
            }}
          />
          <BaseButton
            color="red"
            textId="justDelete"
            onClick={() => {
              deleteCustomFilter(deleteId);
              setIsDeleteOpen(false);
              if (+filterId === +deleteId) {
                handleResetFilter();
              }
            }}
          />
        </div>
      </BasicCRMModal>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

export default compose(
  withRouter,
  injectIntl,
  connect(state => ({
    areThereAnySelectedContacts: !!state.crm.contacts.selectedContactsIds.length,
  }))
)(ActionsTab);
