import { call, fork, put, take } from 'redux-saga/effects';
import { REQUEST } from 'redux-config/reduxHelpers';
import * as api from 'crm/_api/api';
import { SubmissionError } from 'redux-form';
import { acceptError } from 'common/_redux';
import { getAllReassignsTasksSaga } from 'crm/tasks/_redux/sagas';
import * as ActionTypes from '../actions';

export function* changeReassignTaskStatusSaga({ search, ...payload }) {
  try {
    yield call(getAllReassignsTasksSaga, { search });
    const { status, data } = yield call(api.changeReassignTaskStatus, payload);
    if (status < 300) {
      yield put(ActionTypes.changeReassignTaskStatus.success());
      yield call(getAllReassignsTasksSaga, { search });
    } else {
      const error = data.detail ? { _error: data.detail } : data;
      yield put(acceptError(data.detail, true));
      yield put(ActionTypes.changeReassignTaskStatus.failure(new SubmissionError(error)));
    }
  } catch (error) {
    yield put(acceptError(error, true));
    yield put(ActionTypes.changeReassignTaskStatus.failure(error));
  }
}

export function* changeReassignTaskStatusWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.CHANGE_REASSIGN_TASK_STATUS[REQUEST]);
    yield call(changeReassignTaskStatusSaga, data);
  }
}
export function* getCRMFastFiltersSaga() {
  try {
    const { data } = yield call(api.getCRMFastFilters);
    yield put(ActionTypes.getCRMFastFilters.success(data));
  } catch (error) {
    yield put(ActionTypes.getCRMFastFilters.failure(error));
  }
}

export function* getCRMFastFiltersWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_CRM_FAST_FILTERS[REQUEST]);
    yield call(getCRMFastFiltersSaga);
  }
}

export function* getCRMCustomFilterSaga(id) {
  try {
    const { status, data } = yield call(api.getCRMFilter, id);
    if (status < 300) {
      yield put(ActionTypes.getCRMCustomFilter.success(data));
    } else {
      yield put(ActionTypes.getCRMCustomFilter.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getCRMCustomFilter.failure());
  }
}

export function* getCRMCustomFilterSagaWatcher() {
  while (true) {
    const { id } = yield take(ActionTypes.GET_CRM_CUSTOM_FILTER[REQUEST]);
    yield call(getCRMCustomFilterSaga, id);
  }
}

export function* getCRMFiltersListSaga() {
  try {
    const { data } = yield call(api.getCRMFiltersList);
    yield put(ActionTypes.getCRMFiltersList.success(data));
  } catch (error) {
    yield put(ActionTypes.getCRMFiltersList.failure(error));
  }
}

export function* getCRMFiltersListWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_CRM_FILTERS_LIST[REQUEST]);
    yield call(getCRMFiltersListSaga);
  }
}

export function* getCRMFiltersOptionsSaga() {
  try {
    const { data } = yield call(api.getCRMFiltersOptions);
    yield put(ActionTypes.getCRMFiltersOptions.success(data));
  } catch (error) {
    yield put(ActionTypes.getCRMFiltersOptions.failure(error));
  }
}

export function* getCRMFiltersOptionsWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_CRM_FILTERS_OPTIONS[REQUEST]);
    yield call(getCRMFiltersOptionsSaga);
  }
}

export function* createCRMCustomFilterSaga(payload) {
  try {
    const { status, data } = yield call(api.createCRMCustomFilter, payload);
    if (status < 300) {
      yield put(ActionTypes.createCRMCustomFilter.success(true));
      yield call(getCRMFiltersListSaga);
    } else {
      const error = data.detail ? { _error: data.detail } : data;
      yield put(ActionTypes.createCRMCustomFilter.failure(new SubmissionError(error)));
    }
  } catch (error) {
    yield put(ActionTypes.createCRMCustomFilter.failure(error));
  }
}

export function* createCRMCustomFilterWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.createCRMCustomFilter.REQUEST);
    yield call(createCRMCustomFilterSaga, payload);
  }
}

export default [
  fork(changeReassignTaskStatusWatcherSaga),
  fork(getCRMFastFiltersWatcherSaga),
  fork(getCRMFiltersListWatcherSaga),
  fork(getCRMFiltersOptionsWatcherSaga),
  fork(createCRMCustomFilterWatcherSaga),
  fork(getCRMCustomFilterSagaWatcher),
];
